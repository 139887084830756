#featured-row{
    background: var(--ui-bg);
    padding: 20px 0;
    display: flex;
    justify-content: center;
}

#featured-row .title h2{
    font-size: var(--font-xl);
    text-align: center;
    margin-bottom: 1rem;
}

#featured-row .buttons{
    display: flex;
    justify-content: center;
    margin: 2rem 0 0;
}

#featured-row .static-items{
    display: flex;
}