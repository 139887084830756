.purchase-products{
  position: relative;
}

.purchase-products .product-actions{
  margin-left: 20px;
}

.purchase-products .product-name{
  display: flex;
  flex-direction: column;
  font-size: var(--font-m)
}

.purchase-products .product-name .name{
  display: flex;
  align-items: center;
}

.purchase-products .product-name .alternative .fa{
  font-size: var(--font-s);
  color: var(--main-color);
  margin-left: 10px;
}

.purchase-products .product-name .category{
  color: var(--ui-placeholder);
  font-size: var(--font-s)
}

.purchase-products .product-name .category .fa{
  margin-right: 5px;
  margin-top: 5px;
  font-size: var(--font-xs)
}

.purchase-products .purchase-product{
  border-bottom: 1px solid var(--ui-border);
  padding: 20px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.purchase-products .purchase-product .instructions{
  margin-top: 5px;
  display: block;
}

.purchase-products .purchase-product .addons{
  color: var(--ui-placeholder);
  margin-top: 20px;
}

.purchase-products .purchase-product .addons .selected-option{
  display: flex;
  align-items: center;
}

.purchase-products .purchase-product .addons .selected-option .price-wrapper{
  margin-left: 5px;
}

.purchase-products .purchase-product .product-price-box{
  margin-left: auto;
  align-items: center;
}

.purchase-products .purchase-product .product-price-box .qty{
  color: var(--main-color);
}

.purchase-products .disabled{
  color: var(--invalid-color);
  font-size: var(--font-s);
  margin-top: 5px;
}
