#delivery-fee-details{
  font-size: var(--font-xs);
  white-space: nowrap;
}

#delivery-fee-details tr td:first-child{
  color: var(--ui-placeholder);
  padding: 0 5px 5px 0
}

#delivery-fee-details tr td:last-child{
  text-align: right;
}
