
.icon-box{
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 2rem;
    flex-wrap: wrap;
  }
  
  .icon-box > .item{
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .icon-box > .item img{
    width: 150px;
    height: auto;
  }
  
  .icon-box > .item .title{
    font-size: var(--font-xl);
    font-weight: bold;
    margin: 1rem 0;
  }
  
  .icon-box > .item .info{
    font-size: var(--font-m);
    line-height: 1.2;
    margin-bottom: 1rem;  
  }
  
  .icon-box > .item a{
    color: var(--main-color);
    font-weight: bold;
    font-size: var(--font-m);
    display: block;
    margin-top: auto;
  }
  
  @media only screen and (max-width: 1024px) {
  
    .icon-box > .item{
      flex: initial;
      flex-basis: 100%;
    }
  
  }