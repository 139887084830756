#purchase-alerts{
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0;
}

#purchase-alerts:first-child{
  padding-top: 10px;
}

#purchase-alerts .purchase-alert{
    display: flex;
    align-items: center;
    line-height: 1.2;
}

#purchase-alerts .purchase-alert .fa{
  margin-right: 10px;
  font-size: var(--font-l)
}

#purchase-alerts .purchase-alert.error .fa{
  color: var(--invalid-color);
}

#purchase-alerts .purchase-alert.warning .fa{
  color: var(--warning-color);
}

#purchase-alerts .help-box{
  justify-content: center;
}

#purchase-alerts .help-box .label{
  margin: 10px 0;
}
