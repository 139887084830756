#register-form .form .actions{
  display: flex;
  margin-bottom: 10px;
}

#register-form #social-logins{
  display: flex;
  margin-top: 40px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#register-form #social-logins .help{
  margin: 20px 0;
  text-align: center;
  font-size: var(--font-m);
}

#register-form .terms{
  margin: 15px 0;
}

#register-form .help-box{
  margin-top: 20px;
  justify-content: center;
}

#register-form .referal{
  display: flex;
  align-items: center;
}

#register-form .referal .labeled-input{
  margin-left: auto;
}

@media only screen and (max-width: 600px) {
  
  #register-form .referal{
    flex-direction: column;
    align-items: flex-start;
  }

  #register-form .referal .labeled-input{
    flex: 1;
    margin: 15px 0 0;
    width: 100%;
  }

}