.form{
  display: flex;
  gap: 15px;
  flex-direction: column;
}

.form > *{
  width: 100%;
}

.form .form-fieldset{
  display: flex;
  gap: 15px;
  align-items: center;
}

.form .form-fieldset.top{
  align-items: flex-start;
}

.form .form-field.fluid{
  flex: 1
}

.form .form-field.centered{
  justify-content: center;
  display: flex;
  align-items: center;
}

/* DEPRECATED */
.form .field{
  display: flex;
  margin: 15px 0;
}

.form .field > *{
  flex: 1;
  margin-right: 10px;
}

.form .field > *:last-child{
  margin-right: 0;
}

.form .warning{
  margin: 10px 5px;
  font-size: var(--font-xs);
  color: var(--ui-placeholder)
}
