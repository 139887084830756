#conditions p{
  color: var(--ui-placeholder);
  text-align: center;
  margin-bottom: 10px;
  line-height: 1.1;
  font-size: var(--font-xs)
}

#delivery-service{
  background: var(--main-bg);
}

#menu-filter{
  z-index: 100;  
  background-color: var(--main-bg);
}

#menu-filter .row{
  margin: 20px 0;
}

#menu-filter .mobile-select .filter-dropdown-toggle .fa{
  padding-left: 0;
}

#menu-filter .margin-left{
  margin-left: 40px;
}

#menu-filter .fill{
  width: 30px;
  display: block;
  flex: none;
  content: "";
}

#menu-filter .filter-bar > *:first-child{
  margin-left: 40px;
}

#menu-filter .filter-bar > *:last-child{
  margin-right: 40px;
}

@media only screen and (max-width: 414px) {

  #menu-filter .filter-bar > *:first-child{
    margin-left: 20px;
  }

  #menu-filter .filter-bar > *:last-child{
    margin-right: 20px;
  }
  
  #menu-filter .fill{
    width: 10px;
  }

}