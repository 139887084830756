.cover-image{
  height: 700px;
  position: relative;
  background-size: cover;
}

.cover-image .content-box{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .5);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.cover-image .content-box > .title{
  font-size: 48px;
  color: var(--main-text);
  text-shadow: rgb(0, 0, 0) 0px 0px 12px;
  text-align: center;
}

.cover-image .content-box > a .button,
.cover-image .content-box > .button{
  font-size: var(--font-l);
  padding: 15px 20px;
  margin-top: 20px;
}


@media only screen and (max-width: 800px) {
  .cover-image{
    height: 600px;
  }

}

@media only screen and (max-width: 414px) {

  .cover-image .content-box > .title{
    font-size: 36px;
    text-align: center;
  }

  .cover-image .content-box > a .button,
  .cover-image .content-box > .button{
    padding: 10px 15px;
  }

}
