#single-partner{
  background: var(--main-bg);
}

#single-partner.skeleton .skeleton-thumb{
  width: 100px;
  height: 100px;
  background: var(--ui-border);
  border-radius: 25%;
  margin: 8px;
  background-image: linear-gradient(90deg, var(--ui-bg) 0px, rgba(229, 229, 229, 0.8) 40px, var(--ui-bg) 80px);
  background-size: 600px;
  animation: shine-avatar 2s infinite ease-out;
}

#single-partner.skeleton .line {
  width: 300px;
  height: 16px;
  background: var(--ui-border);
  border-radius: 7px;
  background-image: linear-gradient(90deg, var(--ui-bg) 0px, rgba(229, 229, 229, 0.8) 40px, var(--ui-bg) 80px);
  background-size: 600px;
  animation: shine-lines 2s infinite ease-out;
}

#single-partner.skeleton .partner-logo-wrapper{
  display: flex;
  align-items: center;
  flex: 1;
}

#single-partner .featured-background{
  height: 280px;
  background-size: cover;
  background-position: center;
}

#single-partner .partner-header{
  background: var(--main-bg);
  padding: 10px 0;
}

#single-partner .partner-header > .row{
  display: flex;
}

#single-partner .partner-logo-wrapper{
  display: flex;
  align-items: center;
}

#single-partner .partner-logo{
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-shadow: 0px 0px 5px 0px rgba(180,180,180,0.75);
  box-sizing: border-box;
  background: var(--main-bg);
  border-radius: 5px;
}

#single-partner .partner-logo picture,
#single-partner.featured .partner-logo picture{
  margin: 0;
  padding: 0;
  width: 80px;
  height: 80px;
}

#single-partner .partner-logo img{
  width: 100%;
  height: auto;
}

#single-partner .partner-title{
  padding: 10px;
  display: flex;
  flex-direction: column;
}

#single-partner .partner-title .vendor-rating{
  justify-content: flex-start;
}

#single-partner .partner-title .title{
  font-size: var(--font-l);
}

#single-partner .partner-title .desc{
  color: var(--ui-placeholder);
  margin-top: 5px;
}

#single-partner .info-bar{
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  margin-left: auto;
}

#single-partner .info-bar > *{
  margin-bottom: 5px
}

#single-partner-content{
  display: flex;
  padding: 25px 0;
}

#single-partner .category-list-wrapper{
  background: var(--main-bg);
  border-bottom: 1px solid var(--ui-border);
  position: sticky;
  position: -webkit-sticky;
  top: 60px;
  z-index: 700;
}

@media only screen and (max-width: 900px) {

  #single-partner .partner-logo-wrapper{
    justify-content: center;
  }

  .category-list-wrapper .row.wrapped{
    padding: 0;
  }

  #single-partner .partner-logo picture, 
  #single-partner.featured .partner-logo picture,  
  #single-partner .partner-logo{
    width: 60px;
    height: 60px;
  }

}

@media only screen and (max-width: 720px) {

  #single-partner .featured-background{
    height: 200px
  }

  #single-partner .partner-header > .row{
    flex-direction: column;
  }

  #single-partner .info-bar{
    margin: 15px auto 0
  }

}
