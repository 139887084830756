#purchase-tip{
  padding: 10px 0 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#purchase-tip .tip-slider{
  position: relative;
}

#purchase-tip .custom{
  flex: 1
}

#purchase-tip .help{
  font-size: var(--font-xs);
  color: var(--ui-placeholder)
}

#purchase-tip .buttons{
  display: flex;
  gap: 5px
}

#purchase-tip .buttons button{
  font-size: var(--font-xs);
  padding: 5px 8px;
  text-align: center;
  flex: 1;
  min-width: 60px;
  box-sizing: border-box;
}
#purchase-tip .buttons button.custom{
  flex: 2;
}

#purchase-tip .buttons button > span{
  display: flex;
  flex-direction: column;
}

#purchase-tip .buttons button > span span:first-child{
  font-weight: bold;
}

#purchase-tip .buttons button > span .amount{
  font-size: var(--font-xs);
}

#purchase-tip .buttons button.active{
  background: var(--main-color);
  color: var(--main-text)
}

#purchase-tip-modal .tip-amount{
  text-align: center;
  display: block;
  width: 100%;
  font-size: var(--font-l);
  font-weight: bold;
}