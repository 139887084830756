#header{
  position: fixed;
  z-index: 8000;
  width: 100vw;
  top: 0;
  z-index: 8000;
  height: 60px;
  background: var(--main-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--ui-border);
  box-sizing: border-box;
  padding: 0 15px;
}

#header .logo img{
  width: auto;
  max-width: 300px;
}

#header .block{
  flex: 1;
  display: flex;
  gap: 15px;
  align-items: center;
}

#header-wrapper .cart-button,
#header-wrapper .select-location-button{
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
}

#header-wrapper .select-location-button,
#header-wrapper .select-location-button svg,
#header-wrapper .select-location-button .fa{
  color: var(--ui-text);
}

#header .cart-button.slide-in-button .qty{
  width: 15px;
  height: 15px;
  background-color: var(--main-color);
  color: var(--main-title);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 3px;
  line-height: 15px;
  font-size: var(--font-xxs);
}

#header-wrapper .select-location-button .fa:before{
  vertical-align: super;
}

#header-wrapper .select-location-button .selected-location{
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: center;
}

#header-wrapper .select-location-button .selected-location .name{
  max-width: 160px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

#header-wrapper .select-location-button .selected-location .zone{
  font-size: var(--font-xs);
  color: var(--ui-placeholder);
}

#header-wrapper .header-mobil-location{
  height: 45px;
  background-color: var(--main-bg);
  padding: 5px 10px;
  display: flex;
  border-bottom: 1px solid var(--ui-border);
  z-index: 800;
  position: fixed;
  top: 60px;
  width: 100vw;
  box-sizing: border-box;
}

#header-wrapper .header-mobil-location .selected-location,
#header-wrapper .header-mobil-location .select-location-button{
  font-size: var(--font-s);
}

#header .separator{
  border-left: 2px solid var(--ui-border);
  padding-left: 15px;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
}

#header .main-nav{
  margin-right: auto;
}

#header .user{
  margin-left: auto;
}

#header .user .loader{
  margin: 0;
  margin-left: auto;
}

#header .slide-in-button .fa{
  font-size: 20px;
  color: var(--ui-text);
}

#header .slide-in-button{
  display: flex;
  align-items: center;
}

#header .user-welcome{
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

#header .user-welcome .greeting{
  font-size: var(--font-xs);
  color: var(--ui-placeholder)
}

#header .user-welcome .name{
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 240px;
  overflow: hidden;
}

#header .search-box-responsive,
#header .search-box{
  margin-left: auto;
  z-index: 200;
}

#header .auth-buttons{
  display: flex;
  align-items: center;
  gap: 10px
}

#header .auth-buttons > *{
  white-space: nowrap
}

#auth-modal .title{
  margin-bottom: 10px;
  font-size: var(--font-m)
}
#auth-modal .desc{
  line-height: 1.2;
  margin-bottom: 20px;
}

#auth-modal .login-widget{
  padding: 20px;
}

.header-purchase-mode-button{
  margin-left: auto;
}

.header-purchase-mode-button .purchase-mode{
  display: flex;
  gap: 10px;
  white-space: nowrap;
  background: var(--main-color);
  padding: 10px;
  border-radius: 50px;
  color: var(--main-title);
  font-size: var(--font-s);
}

.header-purchase-mode-button .purchase-mode .fa{
  font-size: var(--font-xs);
}

@media only screen and (max-width: 800px) {
  
  #main.authenticated #search-results,
  #main.authenticated #checkout,
  #main.authenticated #delivery-service{
    padding-top: 45px;
  }
  
}

@media only screen and (max-width: 550px) {
  
  #header .cart-button.slide-in-button .qty{
    position: absolute;
    top: -12px;
    right: -12px;
    z-index: 10;
  }

}

@media only screen and (max-width: 414px) {
  
  #header .separator{
    padding-left: 10px;
  }

  #header .auth-buttons > *{
    white-space: wrap;
    font-size: var(--font-xs);
  }

}