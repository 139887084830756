@-webkit-keyframes myanimation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@-webkit-keyframes myanimation2 {
  0% {
    transform: scale(1);
  }
  50%{
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.checkout-wrap {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}

ul.checkout-bar {
  margin: 0;
  width: 100%;
}

ul.checkout-bar li {
  color: var(--ui-placeholder);
  display: block;
  padding: 14px 20px 14px 80px;
  position: relative;
}

ul.checkout-bar li:before {
  -webkit-box-shadow: inset 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
  box-shadow: inset 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
  background: #ddd;
  border: 2px solid #FFF;
  border-radius: 50%;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  left: 20px;
  line-height: 37px;
  height: 35px;
  position: absolute;
  text-align: center;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
  top: 4px;
  width: 35px;
  z-index: 600;
}

ul.checkout-bar li.active {
  color: var(--main-color);
  font-weight: bold;
}

ul.checkout-bar li.active:before {
  background: var(--main-color);
  z-index: 700;
  -webkit-animation: myanimation2 3s;
}

ul.checkout-bar li.visited {
  color: var(--valid-color);
  z-index: 600;
}

ul.checkout-bar li.visited:before {
  background: var(--valid-color);
  z-index: 700;
}

ul.checkout-bar li:nth-child(1):before {
  content: "1";
}

ul.checkout-bar li:nth-child(2):before {
  content: "2";
}

ul.checkout-bar li:nth-child(3):before {
  content: "3";
}

ul.checkout-bar li:nth-child(4):before {
  content: "4";
}

ul.checkout-bar li:nth-child(5):before {
  content: "5";
}

ul.checkout-bar li:nth-child(6):before {
  content: "6";
}

ul.checkout-bar{
  width: auto;
}

@media all and (min-width: 800px) {

  .checkout-bar li.active:after {
    -webkit-animation: myanimation 3s;
    background-size: 35px 35px;
    background-color: var(--main-color);
    background-image: -webkit-linear-gradient(-45deg,hsla(0,0%,100%,.2) 25%,transparent 0,transparent 50%,hsla(0,0%,100%,.2) 0,hsla(0,0%,100%,.2) 75%,transparent 0,transparent);
    -webkit-box-shadow: inset 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
    box-shadow: inset 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
    content: "";
    height: 15px;
    width: 100%;
    left: 50%;
    position: absolute;
    top: -50px;
    z-index: 0;
  }

  .checkout-bar li:last-child.active:after{
    display: none;
  }

  ul.checkout-bar {
    -webkit-box-shadow: inset 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
    box-shadow: inset 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
    background-size: 35px 35px;
    background-color: #EcEcEc;
    background-image: -webkit-linear-gradient(-45deg,hsla(0,0%,100%,.2) 25%,transparent 0,transparent 50%,hsla(0,0%,100%,.2) 0,hsla(0,0%,100%,.2) 75%,transparent 0,transparent);
    border-radius: 15px;
    height: 15px;
    margin: 0 auto;
    margin: 40px 0;
    width: 100%;
    display: flex;
  }

  ul.checkout-bar:before {
    background-size: 35px 35px;
    background-color: var(--valid-color);
    background-image: -webkit-linear-gradient(-45deg,hsla(0,0%,100%,.2) 25%,transparent 0,transparent 50%,hsla(0,0%,100%,.2) 0,hsla(0,0%,100%,.2) 75%,transparent 0,transparent);
    -webkit-box-shadow: inset 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
    box-shadow: inset 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    content: " ";
    height: 15px;
    left: 0;
    position: absolute;
    width: 10%;
  }

  ul.checkout-bar.last-active:after {
    background-size: 35px 35px;
    background-color: var(--ui-color);
    background-image: -webkit-linear-gradient(-45deg,hsla(0,0%,100%,.2) 25%,transparent 0,transparent 50%,hsla(0,0%,100%,.2) 0,hsla(0,0%,100%,.2) 75%,transparent 0,transparent);
    -webkit-box-shadow: inset 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
    box-shadow: inset 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    content: " ";
    height: 15px;
    right: 0;
    position: absolute;
    width: 10%;
  }

  ul.checkout-bar li {
    display: inline-block;
    margin: 50px 0 0;
    padding: 0;
    text-align: center;
    flex: 1
  }

  ul.checkout-bar li:before {
    height: 45px;
    left: 38%;
    line-height: 45px;
    position: absolute;
    top: -65px;
    width: 45px;
    z-index: 600;
  }

  ul.checkout-bar li.visited {
    background: none;
  }

  ul.checkout-bar li.visited:after {
    background-size: 35px 35px;
    background-color: var(--valid-color);
    background-image: -webkit-linear-gradient(-45deg,hsla(0,0%,100%,.2) 25%,transparent 0,transparent 50%,hsla(0,0%,100%,.2) 0,hsla(0,0%,100%,.2) 75%,transparent 0,transparent);
    -webkit-box-shadow: inset 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
    box-shadow: inset 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
    content: "";
    height: 15px;
    left: 50%;
    position: absolute;
    top: -50px;
    width: 100%;
    z-index: 600;
  }

}
