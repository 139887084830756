.filter-bar.product-tags-filter{
  margin-bottom: 0;
}

.product-tags-filter{
  display: flex;
  margin-top: auto;
  white-space: nowrap;
  align-items: center;
  overflow-x: auto;
  gap: 10px;
}

.product-tags-filter .product-tag{
  margin: 0;
  color: var(--ui-text);
  cursor: pointer;
  align-items: center;
  font-size: var(--font-s);
  display: flex;
}

.product-tags-filter .filter-button.selected{
  background-color: #d9d9d9;
}

.product-tags-filter .filter-button.selected .fa-trash{
  color: var(--ui-text);
}

.product-tags-filter.padded .filter-button:first-child{
  margin-left: 40px;
}

.product-tags-filter.padded .filter-button:last-child{
  margin-right: 40px;
}

.product-tags-filter .product-tag .fa{
  padding-left: 0;
}

.product-tags-filter .product-tag > span,
.product-tags-filter .product-tag > i{
  margin-right: 5px;
}

.product-tag.valid > span,
.product-tag.valid > i{
  color: var(--valid-color)
}

.product-tag.invalid > span,
.product-tag.invalid > i{
  color: var(--invalid-color)
}


@media only screen and (max-width: 414px) {
  .product-tags-filter.padded .filter-button:first-child{
    margin-left: 20px;
  }
  
  .product-tags-filter.padded .filter-button:last-child{
    margin-right: 20px;
  }  
}