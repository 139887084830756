#order-help-modal .modal-title{
  font-size: var(--font-l);
  font-weight: bold;
}

#order-help-modal .modal-info{
  overflow: auto;
}

#order-help-modal .content a{
  color: var(--main-color)
}

#order-help-modal .content p{
  margin: 10px 0;
}

#order-help-modal .content ul{
  margin-left: 20px;
  padding-left: 20px;
  list-style: disc;
}

#order-help-modal .content ul li{
  margin: 5px 0;
}

.help-modal-faq-item{
  padding: 15px 0;
  cursor: pointer;
  border-bottom: 1px solid var(--ui-border)
}

.help-modal-faq-item .title{
  font-weight: bold;
  font-size: var(--font-m);
  display: flex;
  align-items: center;
}

.help-modal-faq-item .fa{
  color: var(--main-color);
  margin-right: 5px;
}

.help-modal-faq-item:last-child{
  border-bottom: 0;
}

.help-modal-selected .title{
  font-size: var(--font-m);
  padding-bottom: 10px;
  font-weight: bold;
}

.help-modal-selected .content{
  line-height: 1.2
}

.help-modal-selected .actions{
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.help-modal-selected .actions > * {
  flex: 1;
}