#orders .order-skeleton .vendor-name{
  width: 200px;
}

#orders .order-skeleton .order .vendor-logo{
  width: auto;
  height: auto;
}

.order-skeleton .skeleton-thumb{
  width: 80px;
  height: 80px;
  background: var(--ui-border);
  border-radius: 25%;
  margin: 8px;
  background-image: linear-gradient(90deg, var(--ui-bg-helper) 0px, var(--ui-bg) 40px, var(--ui-bg-helper) 80px);
  background-size: 600px;
  animation: shine-avatar 2s infinite ease-out;
}

.order-skeleton .info {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.order-skeleton .line {
  width: 100%;
  height: 16px;
  margin-bottom: 12px;
  background: var(--ui-border);
  border-radius: 7px;
  background-image: linear-gradient(90deg, var(--ui-bg-helper) 0px, var(--ui-bg) 40px, var(--ui-bg-helper) 80px);
  background-size: 600px;
  animation: shine-lines 2s infinite ease-out;
}

.order-skeleton .line ~ .line {
  background-color: #ddd;
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 300px;
  }
}
@keyframes shine-avatar {
  0% {
    background-position: -32px;
  }
  40%, 100% {
    background-position: 208px;
  }
}
