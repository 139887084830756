#checkout .checkout-content{
  display: flex;
}

#checkout .checkout-steps{
  flex: 1;
}

#checkout .checkout-step{
  margin: 0 0 40px;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e6ebf1;
  border-radius: 5px;
  padding: 15px;
  box-sizing: border-box;
}

#checkout .checkout-step-content{
  width: 100%;
}

#checkout #checkout-options{
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 0 0 40px;
}

#checkout #checkout-options:empty {
  margin: 0;
}

@media only screen and (max-width: 1280px) {

  #checkout .checkout-content{
    flex-direction: column;
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
  }

}
