#footer{
  border-top: 1px solid var(--ui-border);
  background: var(--main-bg);
  font-size: var(--font-s);
}

#footer .row{
  position: relative;
}

#footer .fb-like{
  position: absolute;
  right: 40px;
  bottom: 0;
}

#footer .fab{
  color: var(--ui-placeholder)
}

#footer ul.list{
  padding: 0;
}

#footer .list li{
  color: var(--ui-placeholder);
  list-style: none;
  margin: 5px 0;
  cursor: pointer;
}

#footer #footer-bar{
  border-top: 1px solid var(--ui-border);
}

#footer #footer-bar .row{
  display: flex;
  padding: 25px 20px;
  align-items: center;
}

#footer #footer-bar .copyright{
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 20px;
}

#footer #footer-bar .copyright > span{
  vertical-align: sub;
  font-size: var(--font-s);
}

#footer #footer-bar .logo img{
  height: 15px;
}

#footer #footer-bar .footer-bar-menu{
  display: flex;
  align-items: center;
  gap: 15px
}

#footer #footer-bar .footer-bar-menu .footer-menu a{
  font-size: var(--font-s);
  cursor: pointer;
  transition: all .2s ease-in-out;
}

#footer #footer-bar .footer-bar-menu .footer-menu a span{
  vertical-align: sub;
}

#footer i{
  margin-right: 10px;
}

#footer #footer-bar .footer-bar-menu .footer-menu{
  display: flex;
  align-items: center;
  gap: 10px
}

#footer #footer-bar .footer-bar-menu .footer-menu span:last-child{
  margin-right: 0;
}

#footer .list li *:hover,
#footer #footer-bar .footer-bar-menu .footer-menu span:hover{
  color: var(--main-color);
}

/********* ROW  END*********/

#footer .widget .widget-title{
  min-height: 20px;
  font-size: var(--font-m);
  margin-bottom: 1rem;  
}

.download-buttons{
  display: flex;
  flex-direction: column;
  gap: 1rem
}

.download-buttons a > div{
  padding: 2px 8px;
  border-radius: 8px;
  background-color: rgb(73, 73, 73);
  display: inline-flex;
}

@media only screen and (max-width: 920px) {

  #footer .widget-box{
    flex-wrap: wrap;
  }

  #footer .widget-box .widget{
    flex-basis: 50%;
    box-sizing: border-box;
  }

}

@media only screen and (max-width: 800px) {

  #footer .widget .widget-title{
    min-height: 0;
  }

  #footer .widget-box .widget{
    flex-basis: 100%;
  }

  #footer{
    font-size: var(--font)
  }

  #footer #footer-bar .row{
    flex-direction: column;
    align-items: center;
  }

  #footer #footer-bar .copyright{
    margin-left: 0;
  }

  #footer #footer-bar .footer-bar-menu{
    margin: 10px 0;
  }

}

@media only screen and (max-width: 500px) {
  
  #footer #footer-bar .copyright{
    flex-direction: column-reverse;

  }

  #footer .widget-box .widget{
    padding: 10px 20px;
  }

  #footer #footer-bar .footer-bar-menu{
    width: 100%;
    justify-content: center;
    flex-direction: column;
  }

  #footer #footer-bar .footer-bar-menu .footer-menu{
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  #footer #footer-bar .footer-bar-menu .footer-menu span{
    margin: 5px 0;
  }

  #footer #footer-bar .copyright{
    width: 100%;
    align-content: center;
    margin-left: 0;
    justify-content: center;
    margin-top: 5px;
  }

}
