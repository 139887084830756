.infinite-list{
  display: flex;
  flex-direction: column;
}

.infinite-list .load-more{
  margin: 20px auto;
}

.infinite-list .load-more .loader{
  margin: 0;
  width: var(--font-s);
  height: var(--font-s);
  border-width: 3px;
  margin-right: 10px;
}
