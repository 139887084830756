#about-us .call-to-action-form-wrapper{
  justify-content: center;
  text-align: center;
}

#about-us .call-to-action-form-wrapper .info{
  max-width: 800px;
}

.about-us{
  display: flex;
  margin: 40px 0;
}

.about-us .about-us-info{
  flex: 1;
  padding: 40px;
}

@media only screen and (max-width: 800px) {
  .about-us{
    flex-direction: column;
  }
}
