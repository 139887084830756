.modal{
  display: flex;
  flex-direction: row;
  padding: 20px;
  position: relative;
  max-height: 80vh;
}

.modal .modal-info{
  border-radius: 5px;
  background-color: var(--main-bg);
  box-shadow: 0px 8px 8px 4px rgba(0,0,0,.4);
  width: 90vw;
  max-width: 720px;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.modal .modal-info .header-image img{
  width: 100%;
  height: auto;
}

.modal .modal-info .title h2{
  font-size: var(--font-xl);
}

.modal .modal-header{
  padding: 15px;
}

.modal .modal-content{
  margin-bottom: 5px;
  padding: 0 20px;

}

.modal .modal-footer{
  margin-top: auto;
  display: flex;
  gap: 15px;
  min-height: 50px;
  padding: 15px;
  border-top: 1px solid var(--ui-border);
}

.modal .close-button{
  top: 35px;
  right: 35px;
}
