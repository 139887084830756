#profile-form .form .labeled-checkbox,
#profile-form .form .labeled-input,
#profile-form .form .phone-number-input{
  margin-bottom: 10px;
}

#profile-form .form .actions{
  display: flex;
  gap: 10px
}

#profile-form .form .actions > *{
  flex: 1;
}

#profile-form .help-box{
  margin-top: 20px;
  justify-content: center;
}
