.featured-card{
  border: 0;
  margin: 2px 0;
  background: var(--main-bg);
  cursor: pointer;
  border-bottom: 1px solid var(--ui-border);  
}

.featured-card .card-header{
  position: relative;
  border-radius: 20px;
}

.featured-card .partner-logo-wrapper{
  position: absolute;
  bottom: 0;
  left: 5px;
  transform: translateY(50%);
}

.featured-card .partner-logo{
  width: 70px;
  height: 70px;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(180,180,180,0.75);
  background: var(--main-bg);
}

.featured-card .partner-logo picture{
  width: 70px;
  height: 70px;  
}

.featured-card .partner-logo img{
  width: 100%;
}

.featured-card .card-content{
  padding: 10px 10px 0;
}

.featured-card .card-footer{
  margin-top: 5px;
  padding: 5px 10px 5px;
  display: flex;
  flex-wrap: wrap;
}

.featured-card .card-content .title{
  padding-left: 85px;
}

.featured-card .title h3{
  display: flex;
  align-items: center;
  min-height: 25px;
}

.featured-card .card-content .desc{
  color: var(--ui-placeholder);
  margin-top: 5px;
  padding-left: 85px;
  font-size: var(--font-xs);
  min-height: var(--font-xs)
}

.featured-card .thumb{
  width: 100%;
  height: 144px;
  background-color: transparent;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
}
