.announcements{
    margin-bottom: 20px;
}

.announcements .swiper-slide{
    width: fit-content;
    overflow: hidden;
    border-radius: 5px;
    cursor: pointer;
}

.announcements .slider .swiper-slide:first-child{
    padding-left: 40px;
}

.announcements .slider .swiper-slide:last-child{
    margin-right: 40px;
}

.announcements img{
    border-radius: 5px;
    aspect-ratio: 2/1;
    width: 330px;
}

@media only screen and (max-width: 414px) {

    .announcements .slider .swiper-slide:first-child{
        padding-left: 20px;
    }
    
    .announcements .slider .swiper-slide:last-child{
        margin-right: 20px;
    }    

}
