.segmented-control{
    display: inline-flex;
    position: relative;
    align-items: stretch;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    position: relative;
    z-index: 1;
    color: var(--ui-text);
  }
  
  .segmented-control > div{
    padding: 10px 20px;
    display: flex;
    align-items: center;
  }
  
  .segmented-control > div{
    cursor: pointer;
    background: var(--ui-element);
  }
  
  .segmented-control > div:first-child{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    
  }
  
  .segmented-control > div:last-child{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .segmented-control.rounded > div:first-child{
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }

  .segmented-control.rounded > div:last-child{
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }

  .segmented-control > div.selected{
    background: var(--main-color);
    color: var(--main-bg)
  }

  .segmented-control label {
    flex: 1;
    cursor: pointer;
  }
