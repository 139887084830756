.modalv3{
  display: flex;
  flex-direction: row;
  height: 100%;
  max-height: 80vh;
  border-radius: 5px;
  overflow: hidden;
  z-index: 9000;
  box-shadow: 0px 8px 8px 0px var(--ui-shadow);  
}
.ReactModal__Content{
  transform: translate(-50%, 0%);
  animation: slideUpLarge 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.modalv3 .modal-info{
  width: 90vw;
  max-width: 800px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #ffffff
}

/* .padding */

.modalv3.padding .modal-info{
  padding: 0;
}

.modalv3.padding .modal-header{
  padding: 15px;
}

.modalv3.padding .modal-header .title{
 margin-bottom: 0;
}

.modalv3.padding .modal-content{
  margin-bottom: 0;
}
/* .padding end */

.modal.full .modal-info{
  max-width: none;
}

.modalv3 .modal-header{
  display: flex;
  flex-direction: column;
}

.modalv3 .modal-header .title{
  font-size: var(--font-l);
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.modalv3 .modal-content{
  margin-bottom: 5px;
  flex: 1;
  overflow: auto;
  margin-bottom: 5px;
}

.modalv3 .modal-footer{
  margin-top: auto;
  display: flex;
  min-height: 40px;
  padding-top: 10px;
}

@media only screen and (max-width: 600px) {

  .modalv3{
    flex-direction: column;
  }

  .modalv3 .modal-info{
    width: 90vw
  }

}

@keyframes slideUpLarge {
  0% {
    transform: translate(-50%, 0%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}
@keyframes slideDownLarge {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}