.jumbo{
  padding: 80px;
  font-size: var(--font-xxl);
  text-align: center;
  background: var(--ui-bg);
  color: var(--ui-text);
  border-top: 1px solid var(--ui-border);
  border-bottom: 1px solid var(--ui-border)
}

@media only screen and (max-width: 414px) {

  .jumbo{
    padding: 60px 40px;
  }

}
