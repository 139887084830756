.validator{
  position: relative;
}

.validator .errors{
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.validator .error-tag.fa{
  background: var(--invalid-color);
  padding: 5px 7px;
  color: var(--main-text);
  font-size: var(--font-xs)
}

.validator .tooltip-wrapper .tooltip{
  background: var(--invalid-color);
  border: 1px solid var(--invalid-color);
  color: var(--main-text);
  left: auto;
  right: 0;
}

.validator .tooltip-wrapper .tooltip:after{
  border-top-color: var(--invalid-color);
  left: auto;
  right: 3px;
}

.validator .tooltip-wrapper .tooltip{
    opacity: 1;
    -moz-animation: hide 0s ease-in 3s forwards;
    /* Firefox */
    -webkit-animation: hide 0s ease-in 3s forwards;
    /* Safari and Chrome */
    -o-animation: hide 0s ease-in 3s forwards;
    /* Opera */
    animation: hide 0s ease-in 3s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    pointer-events: auto;
    -webkit-transform: translateY(0px);
       -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
         -o-transform: translateY(0px);
            transform: translateY(0px);
}

.tooltip-wrapper .error-tag:hover + .tooltip {
  -moz-animation: show 0s ease-in 1s forwards;
  /* Firefox */
  -webkit-animation: show 0s ease-in 1s forwards;
  /* Safari and Chrome */
  -o-animation: show 0s ease-in 1s forwards;
  /* Opera */
  animation: show 0s ease-in 1s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}


@keyframes hide {
    to {
      opacity: 0
    }
}
@-webkit-keyframes hide {
    to {
      opacity: 0
    }
}

@keyframes show {
    to {
      opacity: 1
    }
}
@-webkit-keyframes show {
    to {
      opacity: 1
    }
}
