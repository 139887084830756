.search-box{
    border-radius: 50px;
    min-width: 260px;
    background-color: var(--ui-element);
    padding: 0 20px;
    display: flex;
    align-items: center;
    color: var(--ui-text);
    position: relative;
}

.search-box .search-input::placeholder{
    color: var(--ui-text);
    font-size: var(--font-xs);
}

.search-box .search-input{
    color: var(--ui-text);
    background-color: var(--ui-element);
    border: 0;
    outline: 0;
    padding: 10px;
    margin: 0;
    resize: none;
    font-size: var(--font-xs);    
    flex: 1
}

.search-box .search-icon{
    color: var(--ui-text);
    font-size: var(--font-s);    
}

.search-box .go-icon{
    font-size: var(--font-s);    
    margin-left: auto;   
    cursor: pointer;
}

.popular-searches{
    position: absolute;
    background: var(--main-bg);
    width: 100%;
    left: 0;   
    bottom: -10px; 
    transform: translateY(100%);
    box-shadow: 0px 0px 5px 0px rgba(200,200,200,0.75);
    border-radius: 5px;
}

.popular-searches .title h3{
    padding: 15px;
    display: flex;
    align-items: center;
}

.popular-searches .title .fa{
    margin-left: auto;
}

.popular-searches .list .list-item{
    padding: 10px 15px;
    border-bottom: 1px solid var(--ui-border);
    cursor: pointer;
    font-size: var(--font-xs);
}

.popular-searches .list .list-item:last-child{
    border: 0;
}


.search-box-responsive{
    border-radius: 50px;
    background-color: var(--ui-element);
    padding: 0 20px;
    display: flex;
    align-items: center;
    color: var(--ui-text);
    height: 38px;
}

.search-box-responsive .search-input{
    display: flex;
    align-items: center;
    gap: 5px;
    width: 0;
    animation-name:shrink;
    animation-duration:200ms;
    animation-timing-function: linear;
    animation-direction: alternate;       
    overflow: hidden;
    position: absolute;
    right: 0;
    box-sizing: border-box;
    height: 40px;
}
.search-box-responsive .search-input input{
    box-sizing: border-box;
    width: 100%;
    color: var(--ui-text);
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 0;
    height: 100%;
    visibility: hidden;
    margin: 0;
    font-size: var(--font-xs);    
}

.search-box-responsive.show .search-input{
    animation-name:grow;
    animation-duration:200ms;
    animation-timing-function: linear;
    animation-fill-mode: forwards;  
    padding: 0 20px;
}

.search-box-responsive .search-input .inner{
    background-color: var(--ui-element);
    border-radius: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
    padding: 0 20px;
    height: 100%;
}

.search-box-responsive.show .search-input input{
    visibility: visible;
}

.search-box-responsive .icon,
.search-box-responsive .search-icon{
    color: var(--ui-text);
    font-size: var(--font-s);    
    cursor: pointer;
}

.search-box-responsive .background{
    position: fixed;
    background: var(--main-bg);
    width: 100%;
    height: 60px;
    left: 0;
    opacity: 0;
    display: none;
}

.search-box-responsive.show .background{
    animation: fadein .2s forwards;
    display: flex;
}

@keyframes grow {
  
    from {
      width:16px;
    }
    
    to {
      width:100%;
      overflow: visible;
    }
    
  }

  @keyframes shrink {
  
    from {
      width: 240px;;
    }
    
    to {
      width: 16px;
      overflow: hidden;
    }
     
  }


@keyframes fadein {
    from { opacity: 0;}
    to   { opacity: 1;}
}