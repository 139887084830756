.filter-bar{
  margin: 0;
  padding: 10px 0;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  gap: 10px;
  align-items: stretch;
}

.filter-bar::-webkit-scrollbar{
  display: none;
}

.filter-bar.vertical{
  flex-direction: column;
}

.filter-dropdown{
  position: relative;
}

.filter-button,
.filter-input,
.filter-dropdown .filter-dropdown-toggle{
  color: var(--ui-text);
  white-space: nowrap;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  padding: 10px 20px;
  background: var(--ui-element);
  border-radius: 5px;
  border: 0;
  box-sizing: border-box;
  cursor: pointer;
  font-size: var(--font-s);
  gap: 10px
}

.filter-dropdown .filter-dropdown-toggle{
  height: 100%;
}

.filter-button.selected,
.filter-input.selected,
.filter-input.selected input,
.filter-dropdown .filter-dropdown-toggle.selected{
  background: var(--main-color);
  color: var(--main-text);
}

.filter-dropdown .filter-dropdown-toggle .toggle{
  padding-left: 10px;
  box-sizing: border-box;
}

.filter-submit {
  padding: 8px 20px;
  font-size: var(--font-s);
  text-align: center;
  background: var(--main-color);
  color: #fff;
  border-color: var(--main-color);
  cursor: pointer;
  margin: 0;
  border-radius: 5px;
  transition: all .2s ease-in-out;
  outline: none;
}

.filter-dropdown-menu{
  display: none;
  position: absolute;
  margin-top: 5px;
  z-index: 800;
  transition:all 200ms ease-in;
  min-width: 100%;
  overflow: hidden;
  border-radius: 5px;
  background: var(--ui-element);
  box-shadow: 0px 8px 8px 0px rgba(0,0,0,.4);
}

.filter-dropdown-menu.right{
  right: 0;
}

.filter-dropdown-menu.opened{
  display: flex;
}

.filter-input{
  position: relative;
}

.filter-input input{
  border: 0;
  min-width: 150px;
  padding: 0;
  background: transparent;
  width: 100%;
}

.filter-input .icon{
  margin-right: 5px;
}

.filter-input .clear{
  right: 20px;
  position: absolute;
}

.filter-select{
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 40vh;
  overflow: auto;
  border: 1px solid var(--ui-border);
  border-radius: 5px;
}

.filter-select li{
  padding: 20px;
  min-width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  white-space: nowrap;
}

.filter-select li *{
  line-height: var(--font-s)
}

.filter-select li .fa{
  margin-right: 10px;
  color: var(--ui-placeholder);
  font-size: var(--font-xs)
}

.filter-select li:hover{
  background: var(--ui-element-hvr)
}

.filter-select li div{
  display: flex;
  flex-wrap: nowrap;
  align-items: center
}

.filter-active-params{
  display: flex;
  margin: 10px 0 0;
  align-items: center;
}

.filter-active-params > div {
  background: var(--ui-border);
  font-size: var(--font-xs);
  border-radius: 20px;
  color: var(--main-text);
  display: inline-block;
  line-height: 26px;
  padding: 5px 15px;
  position: relative;
  white-space: nowrap;
  margin-right: 10px;
  text-decoration: none;
  -webkit-transition: all 0.2s;
  cursor: pointer;
}

.filter-active-params > div .fa{
  font-size: var(--font-xxs);
  margin-left: 10px;
}


.filter-active-params > div:hover {
  background-color: var(--main-color);
}

.filter-active-params > div:hover::after {
   border-left-color: var(--main-color);
}

.portal-modal .modal-content{
  margin-bottom: 10px;
}

.portal-modal .modal-footer{
  border-top: 0;
  display: flex;
  width: 100%;
  padding: 0 20px 10px;
  box-sizing: border-box;
}

.portal-modal .modal-footer > *{
  flex: 1
}

.portal-modal .modal-footer{
  min-height: 0;
}


.portal-modal .filter-select{
  gap: 10px
}

.portal-modal .filter-select li{
  padding: 20px;
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  background: var(--ui-element);
}

@media only screen and (max-width: 1023px) {
  .filter-bar{
    overflow: scroll;
  }
}
