.purchases-list .purchase{
  background: var(--main-bg);
  border-radius: 5px;
  margin: 20px 0;
  padding: 40px; position: relative;
  box-shadow: 0px 0px 5px 0px rgba(200,200,200,0.75);
}

.purchases-list .purchase .checkout-bar{
  margin-top: 15px;
}

.purchases-list .purchase .purchase-inner{
  display: flex;
  align-items: center;
}

.purchases-list .purchase .vendor-logo{
  align-items: center;
  display: flex;
  width: 80px;
  height: 80px;
  flex: none;
  border-radius: 5px;
  overflow: hidden;
}

.purchases-list .purchase .vendor-logo picture{
  width: 80px;
  height: 80px;
}

.purchases-list .purchase .vendor-logo img{
    height: auto;
    width: 80px;
}

.purchases-list .purchase .purchase-vendor-box{
  display: flex;
  align-items: center;
  flex:1;
  gap: 10px;
}

.purchases-list .purchase .vendor-name{
  display: flex;
  flex-direction: column;
}

.purchases-list .purchase .vendor-name span:last-child{
  color: var(--ui-placeholder);
}

.purchases-list .purchase .purchase-info-bar{
  display: flex;
}

.purchases-list .purchase .purchase-status-box{
  flex: 2;
  margin: 0 auto 15px;
  text-align: center;
}

.purchases-list .purchase .purchase-info-box{
  flex:1;
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
}

.purchases-list .purchase .purchase-info-box .purchase-date{
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 5px
}

.purchases-list .purchase .purchase-info-box .purchase-date span:last-child{
  color: var(--ui-placeholder);
  font-size: var(--font-xs);
}

.purchases-list .purchase .actions{
  display: flex;
  margin-top: 30px;
  justify-content: center;
  gap: 10px
}

.purchases-list .purchase .purchase-info-box .live-updates{
  display: flex;
}

.purchases-list .purchase .purchase-info-box .live-updates > div{
  display: flex;
  align-items: center;
}

.purchases-list .purchase .purchase-info-box .live-updates .dot{
  width: 10px;
  height: 10px;
  background: var(--main-color);
  border-radius: 5px;
  display: block;
  margin-right: 5px;
}

.purchases-list .purchase .ratings{
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-evenly;
}

.purchases-list .purchase .ratings .rating{
  margin-right: 40px;
}

.blink {
  animation: blink-animation 1s steps(5, start) infinite;
}


@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}


@media only screen and (max-width: 1023px) {

  .purchases-list .purchase .purchase-info-bar{
    flex-direction: column;
  }

  .purchases-list .purchase .purchase-vendor-box{
    margin-bottom: 20px;
  }

}

@media only screen and (max-width: 960px) {

  .purchases-list .purchase{
    justify-content: center;
    flex-direction: column;
    padding: 20px
  }
  
  .purchases-list .purchase .purchase-inner{
    justify-content: center;
    flex-direction: column;
  }

  .purchases-list .purchase .ratings .rating{
    margin: 10px 0 0
  }

  .purchases-list .purchase .ratings{
    margin: 20px auto 0;
    text-align: center;
    flex-direction: column;
    display: flex;
    align-items: center;
  }

  .purchases-list .purchase .purchase-vendor-box{
    margin: 0 0 20px;
    justify-content: center;
    flex-direction: column;
  }

  .purchases-list .purchase .purchase-info-box{
    margin: 20px 0 0;
    justify-content: center;
  }

  .purchases-list .purchase .ratings .faces{
    display: flex;
    justify-content: center;
  }

  .purchases-list .purchase .actions{
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    display: flex;
  }

}

@media only screen and (max-width: 540px) {

  .purchases-list .purchase .purchase-info-bar{
    flex-direction: column;
  }

  .purchases-list .purchase .purchase-vendor-box{
    justify-content: center;
  }

  .purchases-list .purchase .purchase-info-box{
    justify-content: center;
    width: 100%;
  }

  .purchases-list .purchase .actions{
    flex-direction: column;
    gap: 10px
  }  

}

@media only screen and (max-width: 414px) {

  .purchases-list .purchase{
    padding: 20px
  }

  .purchases-list .purchase .vendor-name{
    width: 100%;
  }  

}