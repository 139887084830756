#left{
  background: var(--main-bg)
}

#left .language-selector{
  display: flex;
  border-bottom: 1px solid var(--ui-border)
}

#left .language-selector .language{
  flex: 1;
  text-align: center;
  display: flex;
  margin: 0;
  justify-content: center;
  border-right: 1px solid var(--ui-border)
}

#left .language-selector .language:last-child{
  border-right: 0;
}
