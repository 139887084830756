#purchase-summary{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;
}

#purchase-summary .summary-row{
  display: flex;
  width: 100%;
  font-size: var(--font-s)
}

#purchase-summary .summary-row .tooltip-wrapper .fa{
  color: var(--ui-placeholder);
  font-size: var(--font-xs);
  margin-left: 5px;
}

#purchase-summary .summary-row .tooltip{
  font-size: var(--font-xs);
  line-height: 1.2
}

#purchase-summary .summary-promotions{
  display: flex;
  flex-direction: column;
  color: var(--main-title);
  gap: 10px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
}

#purchase-summary .summary-promotions .promotion{
  box-sizing: border-box;
  border-radius: 5px;
  flex: 1;
  width: 100%;
  padding: 15px;
  background-color: var(--valid-color);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px
}

#purchase-summary .summary-promotions .promotion .fas:before{
  font-size: var(--font-m);
}

#purchase-summary .summary-row.balance.positive{
  color: var(--valid-color)
}

#purchase-summary .summary-row.balance.negative{
  color: var(--invalid-color)
}

#purchase-summary .summary-row.total{
  border-top: 1px solid var(--ui-border);
  padding: 10px 0 5px;
}

#purchase-summary .summary-row .qty{
  font-size: var(--font-xs);
  color: var(--ui-placeholder);
  margin-left: 5px;
}

#purchase-summary .summary-row .price{
  margin-left: auto;
}

#purchase-summary .summary-row.total .price{
  color: var(--ui-color);
  font-weight: bold;
}