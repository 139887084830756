#how-it-works .title{
  text-align: center;
  margin: 80px 0;
  font-weight: bold;
  font-size: var(--font-xl)
}

#timeline {
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: all 0.4s ease;
}

#timeline:before {
  content: "";
  width: 3px;
  height: 100%;
  background: var(--main-color);
  left: 50%;
  top: 0;
  position: absolute;
}

#timeline:after {
  content: "";
  clear: both;
  display: table;
  width: 100%;
}

#timeline .timeline-item {
  margin-bottom: 50px;
  position: relative;
}

#timeline .timeline-item .timeline-icon {
  background: var(--main-color);
  width: 50px;
  height: 50px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 50%;
  overflow: hidden;
  margin-left: -23px;
  border-radius: 50%;
}

#timeline .timeline-item .timeline-icon .fa {
  position: relative;
  color: var(--main-text);
  font-size: var(--font-l)
}

#timeline .timeline-item .timeline-content {
  width: 45%;
  background: var(--ui-bg);
  border-radius: 5px;
  transition: all 0.3s ease;
  box-sizing: border-box;
  overflow: hidden;
}

#timeline .timeline-item .timeline-content h2 {
  padding: 15px;
  background: var(--main-color);
  color: var(--main-text);
  font-weight: bold;

}

#timeline .timeline-item .timeline-content .info{
  padding: 15px;
  border-top: 0;
}

#timeline .timeline-item .timeline-content:before {
  content: '';
  position: absolute;
  left: 45%;
  top: 20px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid var(--main-color);
}

#timeline .timeline-item:nth-child(even) .timeline-content {
  margin-left: auto
}

#timeline .timeline-item:nth-child(even) .timeline-content:before {
  content: '';
  right: 45%;
  left: inherit;
  border-left: 0;
  border-right: 7px solid var(--main-color);
}

@media screen and (max-width: 768px) {
  #timeline {
    margin: 30px;
    padding: 0px;
    width: 90%;
  }

  #timeline:before {
    left: 0;
  }

  #timeline .timeline-item .timeline-content {
    width: 90%;
    float: right;
  }

  #timeline .timeline-item .timeline-content:before, #timeline .timeline-item .timeline-content.right:before {
    left: 10%;
    margin-left: -6px;
    border-left: 0;
    border-right: 7px solid var(--main-color);
  }

  #timeline .timeline-item .timeline-icon {
    left: 0;
  }
}
