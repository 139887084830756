#report-problem-modal{

}

#report-problem-modal .modal-title{
  font-size: var(--font-l);
  font-weight: bold;
}

#report-problem-modal .report{
  padding: 20px 0;
  font-size: var(--font-m);
}

#report-problem-modal .report .message{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

#report-problem-modal .report .thank-you{
  text-align: justify;
}

#report-problem-modal .report .fa{
  margin-right: 15px;
  font-size: var(--font-xxl);
}

#report-problem-modal .report.valid .fa{
  color: var(--valid-color)
}

#report-problem-modal .report.warning .fa{
  color: var(--warning-color)
}

#report-problem-modal .modal-footer .actions{
  display: flex;
  width: 100%
}

#report-problem-modal .modal-footer .actions .button{
  flex: 1
}

@media only screen and (max-width: 760px) {

  #report-problem-modal .report .message{
    flex-direction: column;
    text-align: center;
  }

  #report-problem-modal .report .fa{
    font-size: 80px;
    margin: 20px 0;
  }

}
