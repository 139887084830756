@media only screen and (max-width: 600px) {

  .purchase-refunds td{
    white-space: nowrap;
  }

  .purchase-refunds{
    overflow: scroll;
  }

}