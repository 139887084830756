#select-location{
  display: flex;
  position: relative;
  overflow: hidden;  
  width: 100%;
}

#select-location .selected-location{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

#select-location .selected-location .selected-map{
  height: 400px;
  flex: 1
}

#select-location .selected-location .selected-map.blur{
  filter: blur(4px);
  pointer-events: none;
}

#select-location .selected-location .selected-map-overlay{
  position: absolute;
}

#select-location #location-list{
  flex: 1;
  overflow: hidden;
}

#select-location #location-list .location-list{
  max-height: 340px;
  overflow-y: scroll;
}

#select-location  #location-list .list-item.add-item .mark .fa{
  font-size: 29px;
  color: var(--ui-color)
}

#select-location  #location-list .select-list .list-item.add-item{
  border-bottom: 1px solid var(--ui-border)
}

@media only screen and (max-width: 800px) {

    #select-location{
      flex-direction: column;
    }

    #location-list{
      width: 100%;
      max-width: none;
      margin-bottom: 20px;
    }

    #location-list .location-list{
      height: auto;
      max-height: none;
    }

    #location-list .list-item.add-item{
      margin-bottom: 10px;
    }

    #select-location .selected-location{
      margin-left: 0;
    }

    #checkout-summary #cart{
      margin-bottom: 0 !important;
    }

}
