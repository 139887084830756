.dropdown {
  display: inline-block;
  z-index: 8000;
  position: relative;
}

.dropdown .toggle {
  display: block;
  border: 1px solid var(--ui-border);
  border-radius: 4px;
  padding: 10px 30px 10px 20px;
  background-color: var(--ui-bg);
  cursor: pointer;
  white-space: nowrap;
}

.dropdown .toggle:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--ui-placeholder);
}

.dropdown .toggle:hover {
  background-color: var(--ui-bg-hvr);
}

.dropdown .toggle .fa{
  margin-right: 10px;
}

.dropdown .menu {
  position: absolute;
  top: 38px;
  left: 0;
  right: 0;
  border: 1px solid var(--ui-border);
  border-radius: 4px;
  padding: 0;
  margin: 2px 0 0 0;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.1);
  background-color: var(--main-bg);
  list-style-type: none;
}

.dropdown .menu{
  display: none;
  max-height: 200px;
  overflow: scroll
}

.dropdown.opened .menu {
  display: block;
}

.dropdown .menu li {
  padding: 10px 20px;
  cursor: pointer;
  white-space: nowrap;
  border-bottom: 1px solid var(--ui-border);
  cursor: pointer;
}

.dropdown .menu li:hover {
  background-color: var(--main-bg-helper);
}

.dropdown .menu li a {
  display: block;
  margin: -10px -20px;
  padding: 10px 20px;
}
