#change-password #change-password-form{
  margin: 40px 0 0;
}

#change-password .form-success{
  display: flex;
  align-items: center;
  padding: 20px 0;
}

#change-password .form-success .fa{
  font-size: var(--font-xxl);
  margin-right: 10px;
  color: var(--valid-color);
}

#change-password .labeled-input{
  margin-bottom: 10px;
}
