.map-pin{
  width: 30px;
  height: 30px;
  border-radius: 30px;
  padding: 5px;
  background: var(--main-color);
  display: flex;
  border-bottom-right-radius: 0;
  position: relative;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -100%) rotate(45deg);
}

.map-pin::after {
  content: "";
  background: rgba(128, 128, 128, 0.2);
  border-radius: 100%;
  position: absolute;
  top: 100%;
  left: 50%;
  height: 30px;
  width: 30px;
  transform: translate(40%, -30%) rotate(45deg) scaleX(0.5);
}

.map-pin .fa{
  color: var(--main-title);
  font-size: var(--font-m);
  transform: rotate(-45deg);
}

.map-pin span{
  font-size: 25px;
  color: var(--main-color);
}

.map-pin .tooltip{
  display: none;
}

.map-pin:hover .tooltip{
  display: block;
  position: absolute;
}
