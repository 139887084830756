.vendor-tags{
  display: flex;
  font-size: var(--font-xs);
  gap: 5px
}

.vendor-tags .vendor-tag{
  width: 15px;
  text-align: center;
  height: auto;
}

.vendor-tags .vendor-tag .main{
  color: var(--main-color)
}

.vendor-tags .vendor-tag .green{
  color: var(--valid-color)
}

.vendor-tags .vendor-tag.slow .warning{
  color: var(--warning-color)
}
