#partners-grid{
  display: flex;
  flex-direction: column;
  gap: 20px; 
}

#partners-grid > .row{
  margin-bottom: 20px;
}

#partners-grid > .row:last-child{
  margin-bottom: 0;
}

.partner-cards{
  display: grid;
grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
row-gap: 15px;
column-gap: 15px;
}

.partner-cards > div{
  box-sizing: border-box;
}

#partners-grid.no-results{
  margin: 40px 0 60px;
  font-size: var(--font-m);
  display: flex;
  justify-content: center;
  align-items: center;
}

#partners-grid.no-results i{
  font-size: var(--font-xl);
  margin-right: 10px;
}

#partners-grid .static-items{
  display: flex;
}

#partners-grid .partner-carousel .row-header{
  padding: 0 40px;
}

#partners-grid .partner-carousel .slider .swiper-slide:first-child{
  padding-left: 40px;
}

#partners-grid .partner-carousel .slider .swiper-slide:last-child{
  padding-right: 40px;
}

@media only screen and (max-width: 1400px) {

  .partner-cards > div{
    flex-basis: 33.33%;
  }

}

@media only screen and (max-width: 1080px) {

  .partner-cards > div{
    flex-basis: 50%;
  }

}

@media only screen and (max-width: 740px) {
  
  .partner-cards > div{
    flex-basis: 100%;
  }
  
}

@media only screen and (max-width: 414px) {

  #partners-grid .partner-carousel .row-header{
    padding: 0 20px;
  }
  
  #partners-grid .partner-carousel .slider .swiper-slide:first-child{
    padding-left: 20px;
  }
  
  #partners-grid .partner-carousel .slider .swiper-slide:last-child{
    padding-right: 20px;
  }
  

}