#app-download{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--main-bg);
  z-index: 9000;
  overflow: none;
  overscroll-behavior: contain;
}

#app-download .inner{
  padding: 40px;
  padding-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
} 

#app-download .inner-header{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

#app-download .buttons{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

#app-download .button.main{
  font-size: var(--font-xl);
}

#app-download .image{
  margin: 20px 0;
  flex: 1
}

#app-download .image img{
  width: auto;
  height: 100%;
  max-height: 35vh;
}

#app-download h2{
  font-size: var(--font-xl);
  font-weight: bold;
  text-align: left;
  margin-top: 0;
}

#app-download .text{
  margin-bottom: 15px;
}