#stripe > *{
  margin: 10px 0;
}

.StripeElement {
  width: 100%;
  padding: 18px 15px;
  box-sizing: border-box;
  outline: none;
  color: var(--ui-text);
  background: var(--ui-bg);
  border-radius: 5px;
}
