.partner-card-skeleton{
  border: 1px solid transparent;
  border-radius: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 100%;
  box-sizing: border-box;
  background: white
}

.partner-card-skeleton .skeleton-thumb{
  width: 80px;
  height: 80px;
  background: var(--ui-border);
  border-radius: 25%;
  margin: 8px;
  background-image: linear-gradient(90deg, var(--ui-bg) 0px, rgba(229, 229, 229, 0.8) 40px, var(--ui-bg) 80px);
  background-size: 600px;
  animation: shine-avatar 2s infinite ease-out;
}

.partner-card-skeleton .info {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.partner-card-skeleton .line {
  width: 100%;
  height: 16px;
  margin-bottom: 12px;
  background: var(--ui-border);
  border-radius: 7px;
  background-image: linear-gradient(90deg, var(--ui-bg) 0px, rgba(229, 229, 229, 0.8) 40px, var(--ui-bg) 80px);
  background-size: 600px;
  animation: shine-lines 2s infinite ease-out;
}

.partner-card-skeleton .line ~ .line {
  background-color: #ddd;
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 300px;
  }
}
@keyframes shine-avatar {
  0% {
    background-position: -32px;
  }
  40%, 100% {
    background-position: 208px;
  }
}
