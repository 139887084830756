
/********* ROW START*********/

.row.padding{
  padding: 0 40px;
}

.row.wrapped{
    display: inline-block;
    width: 100%;
}

.row.wrapped{
  display: block;
  margin: 25px auto;
  padding: 0 40px;
  max-width: 1440px;
  box-sizing: border-box;
}

.row.wrapped.no-margin{
  margin: 0 auto
}

.row .row-header{
  display: flex;
  margin-bottom: 20px;
}

.row.xl .row-header .title{
  text-align: center;
  width: 100%;
  margin-bottom: 4rem;
  font-weight: bold;
  font-size: var(--font-xxl);
}

.row .row-header .actions{
  margin-left: auto;
  white-space: nowrap;
}

.row.xl{
  margin: 8rem auto;
}

.row-headline{
  background-color: var(--ui-color-dark);
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-text);
  box-sizing: border-box;
}

.row-headline .row.wrapped{
  padding: 40px; 
}

.row-headline .row-header .icon{
  height: 300px;
  margin-left: auto;
}

.row-headline .row-header .icon img{
  height: 100%;
  width: auto;
}

.row-headline .row-header{
  align-items: center;
  flex-wrap: wrap;
}

.row-headline .row-header .title{
  font-size: 40px;
}

.row-headline .row-header .info{
  font-size: var(--font-m);
  margin-top: 2rem;
}

@media only screen and (max-width: 960px) {

  .row.xl{
    margin: 4rem auto;
  }

  .row-headline .row-header{
      justify-content: center;
  }

  .row-headline .row-header .icon{
      margin-left: 0;
      margin-top: 2rem;
  }

  .row-headline .row-header .title{
      font-size: 32px;
  }

}

@media only screen and (max-width: 414px) {

  .row.padding{
    padding: 0 20px;
  }

  .row.wrapped{
    padding: 0 20px;
  }

}

/********* ROW  END*********/




/********** Heading **********/

.heading{
  width: 100%;
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.heading.centered{
  justify-content: center;
  align-items: center;
}

.heading .title{
  font-size: var(--font-xl);
  position: relative;
  font-weight: bold;
}

.heading .desc{
  font-size: var(--font-s);
  color: var(--ui-placeholder);
  margin: 5px 0 ;
}
/* 
.heading .title::before{
  width: 60px;
  height: 4px;
  content: "";
  display: block;
  background: var(--main-color);
  position: absolute;
  bottom: -14px;
  left: 0
} */

.heading.no-desc .title::before{
  display: none;
}

.heading.no-desc .desc{
  margin: 5px 0 0;
}


/********** Heading end **********/

/********** List **********/

ul.list{
  list-style: square;
  padding-left: 20px;
  color: var(--ui-color)
}

ul.list li{
  color: var(--ui-color);
  line-height: 1.2
}

/********** List end **********/

/********** Select List **********/

.select-list{
  display: flex;
  flex-direction: column;
}

.select-list .list-item{
  display: flex;
  cursor: pointer;
  box-sizing: border-box;
  padding: 15px 0;
  /* border-bottom: 1px solid var(--ui-border); */
}

.select-list .list-item .list-item-content{
  display: flex;
  flex: 1;
  gap: 10px;
  overflow: hidden;
  align-items: center;
}

.select-list .list-item .mark{
  display: flex;
  align-items: center;
  margin: 0 15px 0 0;
}

.select-list .list-item .label{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: var(--font-m);
  margin-right: 10px;
}

.select-list .list-item .label .instructions{
  color: var(--ui-placeholder);
  margin-top: 5px;
  font-size: var(--font-s)
}

.select-list .list-item .actions{
  display: flex;
  margin-left: auto;
  padding-right: 10px;
  flex-wrap: nowrap;
}

.select-list .list-item .actions > span{
  color: var(--ui-text);
  padding: 5px;
  font-size: var(--font-xs)
}

@media only screen and (max-width: 800px) {

  .select-list .list-item .actions > span{
    font-size: var(--font-s)
  }

}

/********** Select List End**********/





/********** Info table start **********/

.info-table{
  display: flex;
  flex-direction: column;
}

.info-table .info-table-item{
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  border-bottom: 1px solid var(--ui-border);
}

.info-table .info-table-item .label{
    display: flex;
    align-items: center;
    color: var(--ui-placeholder);
    font-size: var(--font-s);
    line-height: 1;
}

.info-table .info-table-item .label .fa{
  color: var(--main-color);
  margin-right: 10px;
  width: 20px;
  text-align: center;
}

.info-table .info-table-item .info{
  margin-left: auto;
  text-align: right;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

@media only screen and (max-width: 414px) {

  .info-table .info-table-item{
    flex-direction: column;
  }

  .info-table .info-table-item .info{
    margin-left: 0;
    text-align: left;
    margin-top: 5px;
  }

}

/********** Info  table end **********/




/********** Widgets **********/
.widget-box{
  display: flex;
}

.widget-box .widget{
  padding: 20px;
  flex: 1
}

/********** Widgets **********/


/********** Card boxes **********/

.card-boxes-wrapper > .title{
  font-size: var(--font-xl);
  text-align: center;
  margin: 40px 0;
}

.card-boxes{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.card-boxes .card-box{
  flex: 1;
  display: flex;
  align-items: center;
}

.card-boxes .card-box .icon{
  box-sizing: border-box;
  padding: 15px;
  width: 100px;
}

.card-boxes .card-box .icon img{
  width: 70px;
  height: auto;
}

.card-boxes .card-box .title{
  font-size: var(--font-l);
  margin-bottom: 10px;
}


@media only screen and (max-width: 1023px) {

  #card-boxes{
    display: flex;
  }

  #card-boxes .card-boxes{
    flex-direction: column;
  }

}

@media only screen and (max-width: 800px) {

  #card-boxes{
    flex-direction: column;
  }

  #card-boxes .card-boxes{
    align-items: flex-start;
  }

}

@media only screen and (max-width: 414px) {

  .card-boxes .card-box{
    flex-basis: 100%;
  }

}




/********** Card boxes end **********/



/********** Call to action form start **********/

.call-to-action-form-wrapper{
  display: flex;
  align-items: center;
}

.call-to-action-form-wrapper h1{
  color: var(--main-text);
  font-size: var(--font-giant);
  margin-bottom: 10px;
}

.call-to-action-form-wrapper .info{
  color: var(--main-text);
  font-size: var(--font-xl);
  line-height: 1.2
}

.call-to-action-form{
  width: 100%;
  max-width: 320px;
  box-sizing: border-box;
  background: var(--main-bg);
  padding: 20px;
  border-radius: 1%;
  margin-left: auto;
}

.call-to-action-form h2{
  font-size: var(--font-m)
}

.call-to-action-form .labeled-input{
  margin: 20px 0;
}

.call-to-action-form button{
  display: block;
  width: 100%;
}

.call-to-action-form .success{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px;
  text-align: center;
  color: var(--valid-color);
  flex-direction: column;
}

.call-to-action-form .success .fa{
  font-size: var(--font-xxl);
  border: 5px solid var(--valid-color);
  border-radius: 50%;
  padding: 20px;
  color: var(--valid-color);
  margin-bottom: 20px;
}


@media only screen and (max-width: 800px) {

  .call-to-action-form{
    max-width: none
  }

}

@media only screen and (max-width: 414px) {

  .call-to-action-form{
    margin: 0 auto;
  }

  .call-to-action-form h2,
  .call-to-action-form-wrapper h1,
  .call-to-action-form-wrapper .info{
    text-align: center;
  }

}
/********** Call to action form end **********/
