.edit-box{
  display: flex;
  position: relative;
  background: var(--ui-element);
  font-size: var(--font-xs);
  cursor: pointer;
  padding: 15px;
  line-height: var(--font-m);
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px 0px var(--ui-shadow);
  align-items: stretch;
}

.edit-box .actions-wrapper{
  border-left: 0;
  display: flex;
  align-items: center;
}

.edit-box .value-text,
.edit-box .value-input{
  position: relative;
  background: transparent;
  outline: 0;
  margin: 0;
  resize: none;
  overflow: hidden;
  display: flex;
  align-items: center;
  color: var(--ui-text);
  padding: 0;
  border: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.edit-box .value-input{
  padding-top: 20px;
  cursor: pointer;
}

.edit-box.edit .value-input{
  cursor: text
}

.edit-box .label{
  color: var(--main-color);
  font-size: var(--font-xxs);
  position: absolute;
  top: 0;
  left: 0;
}

.edit-box > .icon{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 15px;
  color: var(--ui-placeholder);
}

.edit-box .value-text .fa,
.edit-box .label .fa{
  margin-right: 5px;
}

.edit-box .value-text.placeholder{
  color: var(--ui-placeholder)
}

.edit-box .phone-picker .country{
  margin-right: 15px;
}

.edit-box.phone-edit-box > .icon{
  padding-right: 0;
}

.edit-box .phone-picker{
  flex: 1
}

.modalv3.edit-box-textv2 input,
.modalv3.edit-box-textv2 textarea{
  background-color: var(--ui-element);
  border-radius: 5px;
  box-sizing: border-box;
  width: 100%;
  border: 0;
  padding: 15px;
}

.modalv3.edit-box-textv2 textarea{
  min-height: 100px;
}

.modalv3.edit-box-textv2 .note{
  color: var(--ui-placeholder);
}