#balance-history{
  overflow-x:auto
}

#balance-history > table{
  width: 100%;
  table-layout: fixed;
}

#balance-history th,
#balance-history td{
  text-align: left;
  white-space: nowrap;
}

#balance-history .price-field{
  text-align: right;
}

#balance-history .price-field.plus span{
  color: var(--valid-color)
}

#balance-history .price-field.minus span{
  color: var(--invalid-color)
}

#balance-history .description-field > div{
  display: flex;
  flex-direction: column;
}

#balance-history .description-field > div .message{
  color: var(--ui-placeholder);
  font-size: var(--font-xs);
  margin-top: 3px;
}

#balance-history .item .tomato-point{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#balance-history .item img{
  width: 20px;
  margin-left: 3px;
  height: auto;
}

#balance-history .actions{
  margin-bottom: 15px;
}


@media screen and (max-width: 800px) {
  #balance-history > table{
    border: 0;
  }

  #balance-history > table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  #balance-history > table tr {
    border-bottom: 2px solid var(--ui-border);
    display: block;
    margin-bottom: 20px;
  }

  #balance-history > table td {
    display: block;
    font-size: .8em;
    text-align: right;
  }

  #balance-history > table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    color: var(--ui-placeholder)
  }

  #balance-history > table td:last-child {
    border-bottom: 0;
  }
}
