#login-form .help-box{
  justify-content: center;
}

#login-form .form .actions{
  display: flex;
  margin-bottom: 10px;
  gap: 10px
}

#login-form .form .actions .button{
  flex: 1;
}

#login-form #social-logins{
  display: flex;
  margin-top: 40px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#login-form #social-logins .help{
  margin: 20px 0;
  text-align: center;
  font-size: var(--font-m)
}
