.rating{
  position: relative;
}

.rating .faces > span{
  text-align: center;
  display: block;
}

.rating .help{
  font-size: var(--font-xs);
}

.rating .faces{
  display: flex;
  margin: 10px 0;
  justify-content: center;
}

.rating .faces .fa{
  color: var(--ui-placeholder);
  cursor: pointer;
  font-size: var(--font-l);
  margin-right: 10px;
}

.rating .faces .fa-frown.active,
.rating .faces .fa-frown:hover{
  color: var(--invalid-color)
}

.rating .faces .fa-meh.active,
.rating .faces .fa-meh:hover{
  color: var(--warning-color)
}

.rating .faces .fa-smile.active,
.rating .faces .fa-smile:hover{
  color: var(--valid-color)
}

.rating .rating-form{
  position: absolute;
  min-width: 240px;
  background: var(--main-bg);
  padding: 10px;
  border: 1px solid var(--ui-border);
  left: 0;
  bottom: 0;
  transform: translate(0, 100%);
  z-index: 500
}

.rating .rating-form .button{
  margin-top: 10px;
  width: 100%;
}
