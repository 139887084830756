*{
  font-family: 'Overpass', sans-serif;
}

html{
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
}

body{
  margin: 0;
  padding: 0;
  font-family: 'Overpass', sans-serif;
  color: var(--ui-text)
}

#main{
  padding-top: 60px;
}

#facebook{
  color: rgb(0, 132, 255)
}

#facebook .fab{
  margin-right: 5px;
}

.form-wrapper{
  margin: 40px auto;
  max-width: 800px;
}

.highlight{
  color: var(--ui-color);
}

.link,
.link:visited{
  color: var(--ui-color);
  cursor: pointer;
}

.link:hover{
  text-decoration: underline;
}

input,
textarea{
  border: 1px solid var(--ui-border);
  outline: 0;
  padding: 10px;
  font-size: 14px;
  margin: 0;
  resize: none;
}

input:disabled{
  cursor: not-allowed;
}

.container{
  min-height: calc(100vh - 320px);
}

.placeholder{
  color: var(--ui-placeholder);
  font-size: 14px;
}

body.mobile-install-open,
body.modal-opened,
.ReactModal__Body--open{
  overflow: hidden;
  width: 100%;
  height: 100%;
  /* animation: scaleBack 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards */

}

.ReactModal__Content{
  overflow: visible !important;
}

.ReactModal__Overlay{
  z-index: 8000;
  background-color: rgba(50, 50, 50, 0.5) !important;
  animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards
}

.Toastify__toast{
  margin-bottom: 0 !important
}

.price{
  white-space: nowrap;
}

hr{
  border: 0;
  height: 0;
  border-bottom: 1px solid var(--ui-border);
}

.portal-modal{
  position: fixed;
  z-index: 9000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(25,25,25,.7);
  display: flex;
  align-items: center;
  padding: 20px;
  animation: fadein .2s;
}

.portal-modal .inner{
  background: var(--main-bg);
  border-radius: 10px;
  width: 100%;
  animation: scaleIn .2s;
}

.portal-modal .filter-select{
  border: 0
}

#overlay{
  background: rgba(50, 50, 50, 0.5) !important;
  width: 100%;
  height: 100%;
  z-index: 800;
  position: fixed;
  visibility: hidden;
}

#overlay.visible{
  visibility: visible;
}

@keyframes fadein {
    from { opacity: 0;}
    to   { opacity: 1;}
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0;}
    to   { opacity: 1;}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0;}
    to   { opacity: 1;}
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0;}
    to   { opacity: 1;}
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0;}
    to   { opacity: 1;}
}

@keyframes scaleIn {
    from { transform: scale(0)}
    to   { transform: scale(1)}
}

/* Firefox < 16 */
@-moz-keyframes scaleIn {
    from { transform: scale(0)}
    to   { transform: scale(1)}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes scaleIn {
    from { transform: scale(0)}
    to   { transform: scale(1)}
}

/* Internet Explorer */
@-ms-keyframes scaleIn {
    from { transform: scale(0)}
    to   { transform: scale(1)}
}

/* Opera < 12.1 */
@-o-keyframes scaleIn {
    from { transform: scale(0)}
    to   { transform: scale(1)}
}

@keyframes scaleBack {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.85);
  }
}
@keyframes scaleForward {
  0% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}