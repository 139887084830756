#partner-menu{
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  min-height: 0;
}

#partner-menu > .product-tags{
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: auto;
  gap: 15px;
  justify-content: initial;
  flex: 1;
}

#partner-menu > .product-tags::-webkit-scrollbar{
  display: none;
}

#partner-menu .menu-category{
  margin: 20px 0;
}

#partner-menu .menu-category-header{
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}

#partner-menu .menu-category-header .availability-time{
  margin-left: 10px;
  font-size: var(--font-s);
  color: var(--ui-placeholder);
  padding-right: 10px;
}

#partner-menu .menu-category-header .title h2{
  display: flex;
  align-items: center;
  width: 100%;
}

#skeleton-menu .menu-items,
#product-search .menu-items,
#partner-menu .menu-items{
  display: grid;
  gap: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 446px)); /* Adjust the column width as needed */
  grid-auto-rows: minmax(100px, auto);
  grid-auto-flow: dense row;
  grid-gap: 10px; /* Adjust the gap between items as needed */
 
}

#skeleton-menu .menu-items{
  margin-top: 40px;
}

.menu-item-skeleton{
  flex-basis: calc(25% - 10px);
  border: 1px solid transparent;
  background: var(--main-bg);
  border-radius: 20px;
  box-sizing: border-box;
  margin: 0 5px 10px;
  padding: 15px;
  font-size: var(--font-s);
  font-weight: bold;
  cursor: pointer;
  min-height: 100px;
  flex-direction: column;
  display: flex;
  justify-content: space-evenly
}

.menu-item-skeleton .line {
  width: 300px;
  height: 16px;
  background: var(--ui-border);
  border-radius: 7px;
  background-image: linear-gradient(90deg, var(--ui-bg) 0px, rgba(229, 229, 229, 0.8) 40px, var(--ui-bg) 80px);
  background-size: 600px;
  animation: shine-lines 2s infinite ease-out;
}

#product-search .menu-items .menu-item,
#partner-menu .menu-items .menu-item{
  background: var(--main-bg);
  border-bottom: 1px solid var(--ui-border);
  border-radius: 0;
  box-shadow: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: grid;
  display: flex;
  font-size: var(--font-s);
  font-weight: 700;
  min-height: 100px;
  padding: 0 0 15px;
  position: relative;
}

.menu-items .menu-item .availability{
  color: var(--invalid-color);
  font-size: var(--font-xs);
  display: flex;
  margin-top: 5px;
  align-items: center;
}

.menu-items .menu-item .availability > .fa{
  margin-right: 5px;
}

.menu-items .menu-item .product-cover{
  height: 200px;
  background-size: cover;
  background-position: center;
  margin: 0 0 15px;
  border-radius: 5px;
  position: relative;
}

.menu-items .menu-item .product-cover .product-tags{
  right: 10px;
  bottom: 10px;
  position: absolute;
}

.menu-items .menu-item .product-cover .tag{
  background: var(--main-bg);
  padding: 5px;
  margin: 0 5px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 1px solid var(--ui-border)
}

.menu-items .menu-item .product-thumb{
  width: 100px;
  height: 100px;
  border-radius: 5px;
  margin: auto 0;
  background-size: contain;
  background-position: center;
  margin-right: 15px;
  background-repeat: no-repeat;
}

.menu-items .menu-item .menu-item-info{
  flex-direction: column;
  display: flex;
  flex: 1;
}

.menu-items .menu-item .menu-item-content{
  padding: 0 5px;
}

.menu-items .menu-item .menu-item-info .menu-item-title{
  display: flex;
  margin-bottom: 5px;
  font-weight: 600
}

.menu-items .menu-item .menu-item-info .menu-item-title .title{
  margin-right: 20px;
}

.menu-items .menu-item .price-box{
  display: flex;
  margin-left: auto;
}

.menu-items .menu-item.free-delivery .price-box{
  margin-right: 45px;
}

.menu-items .menu-item .price{
  margin-left: auto;
  white-space: nowrap;
}

.menu-items .menu-item.on-sale .price{
  color: var(--main-color);
  margin-left: 0;
}

.menu-items .menu-item .discounted-price{
  text-decoration: line-through;
  margin-right: 10px;
  margin-left: auto;
}

.menu-items .menu-item .vendor-name,
.menu-items .menu-item .desc{
  color: var(--ui-placeholder);
  font-size: var(--font-s);
  line-height: 1.2;
  font-weight: 400
}

.menu-items .menu-item .vendor-name{
  margin: 0 0 5px;
}

.menu-items .menu-item .vendor-name .fa{
  margin-right: 5px;
  font-size: var(--font-xs);
}

#attention{
margin-top: 20px;
}

#attention p{
  color: var(--ui-placeholder);
  text-align: center;
  margin-bottom: 10px;
  line-height: 1.1;
  font-size: var(--font-xs)
}

#partner-menu .empty-menu{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 80px 0;
  text-align: center;
}

#partner-menu .empty-menu .button{
  margin-top: 10px;
}

@media only screen and (max-width: 1440px) {
  #skeleton-menu .menu-items,
  #product-search .menu-items,
  #partner-menu .menu-items{
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr)); /* Adjust the column width as needed */
  }  
}

@media only screen and (max-width: 1280px) {

    .menu-items .menu-item .tag{
      pointer-events: none;
    }

}

@media only screen and (max-width: 1180px) {
  .menu-items .menu-item{
    flex-basis: calc(100% - 20px);
  }
}

@media only screen and (max-width: 414px) {

  #skeleton-menu .menu-items,
  #product-search .menu-items,
  #partner-menu .menu-items{
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr)); /* Adjust the column width as needed */
  }


  .menu-items .menu-item .product-cover{
    height: 180px;
  }

  .menu-items .menu-item .product-thumb{
    width: 80px;
    height: 80px;
  }

}

@media only screen and (max-width: 320px) {

  .menu-items .menu-item .product-cover{
    height: 160px;
  }

}
