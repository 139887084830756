.select-modal ul{
  display: flex;
  flex-direction: column;
  gap: 0
}

.select-modal ul li{
  position: relative;
  width: 100%;
  display: flex;
  border-bottom: 1px solid var(--ui-border);
  color: var(--ui-text);
  font-size: var(--font-m);
  padding: 15px;
  box-sizing: border-box;
  cursor: pointer;  
  user-select: none;
}

.select-modal ul li.selected .icon{
  margin-left: auto;
  color: var(--main-color);
}

.select-modal ul li:last-child{
  border: 0;
}

.select-modal .no-results{
  padding: 0 15px 15px;
  display: flex;
  justify-content: center;
  text-align: center;
  gap: 15px;
  flex-direction: column;
}

.select-modal .no-results .fa{
  font-size: var(--font-xxl);
  color: var(--ui-placeholder)
}