.text > span{
  margin: 10px 0;
  display: block;
  color: var(--ui-placeholder)
}

.text a{
  text-decoration: underline;
}

.text a:hover{
  text-decoration: underline;
}

.text a.main{
  color: var(--main-color);
}

.text a.secondary{
  color: var(--secondary-color);
}

.text a.main:hover{
  color: var(--main-color-hvr);
}

.text a.secondary:hover{
  color: var(--secondary-color-hvr);
}

.text strong{
  font-weight: bold;
}

.text h2{
  font-size: var(--font-l);
  font-weight: bold;
  margin: 2rem 0 1rem;
  line-height: 1.4;
}

.text h3{
  font-size: var(--font-m);
  font-weight: bold;
  margin: 2rem 0 1rem;
  line-height: 1.4;
}

.text h4{
  font-size: var(--font-l);
  font-weight: bold;
  margin: 2rem 0 1rem;
  line-height: 1.4;
}

.text h5{
  font-size: var(--font-m);
  font-weight: bold;
  margin: 2rem 0 1rem;
  line-height: 1.4;
}

.text p{
  margin: 1rem 0;
  display: block;
  font-size: var(--font-m);
  line-height: 1.4;
  margin-bottom: 1rem;

}

.text ol{
  list-style: decimal;
  padding-left: 4rem;
}

.text ul{
  list-style: disc;
  padding-left: 40px;
  margin: 1rem 0;
  color: var(--main-color)
}

.text ol li,
.text ul li{
  padding: 5px 0;
  color: var(--ui-text);
  font-size: var(--font-m);
  line-height: 1.2;
}


@media only screen and (max-width: 960px) {
  
  .text ol{
    padding-left: 3rem;
  }

}