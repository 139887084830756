.navigation{
  display: flex;
  flex-direction: column;
}

.navigation .nav-item{
  display: flex;
  padding: 10px 0;
  box-sizing: border-box;
  border-bottom: 1px solid var(--ui-border);
  cursor: pointer;
  -webkit-transition: all 0.4s linear;
  -moz-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.navigation .selected .nav-item,
.navigation .nav-item:hover{
  background: var(--main-bg-helper)
}

.navigation .selected .nav-item{
  color: var(--main-color)
}

.navigation .nav-item .icon{
  width: 39px;
  height: 39px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 20px;
  color: var(--main-color)
}

.navigation .nav-item .label{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.navigation .nav-item .label > span{
  font-size: var(--font-s);
}

.navigation .nav-item .label > .info{
  margin-top: 5px;
  font-size: var(--font-xs);
  color: var(--ui-placeholder)
}
