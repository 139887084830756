.active-verification .title{
    font-weight: bold;
    text-align: center;
    font-size: var(--font-m);
    margin: 20px 0 0;
}

.active-verification .phone-number{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.active-verification .phone-number span{
    color: var(--ui-placeholder);
    margin-bottom: 15px;
}

.active-verification .phone-number .cel{
    font-size: var(--font-l);
    font-weight: bold;
}

.active-verification .timer{
    justify-content: center;
    flex: 1;
}

/* Chrome, Safari, Edge, Opera */
.active-verification input::-webkit-outer-spin-button,
.active-verification input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.active-verification input[type=number] {
  -moz-appearance: textfield;
}

.active-verification .help-box{
    margin-top: 15px;
    flex-direction: column;
    gap: 10px;
}

.verify-number{
    padding: 15px 0 0;
}

.verify-number-field{
    display: flex;
    justify-content: center;    
    gap: 10px
}

.verify-number-field .placeholder{
    font-size: var(--font-s);
    text-align: center;
}

.verify-number-field .phone-number{
    font-size: var(--font-l);
}

.verify-number-field > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;    
}

.verify-number-field .phone-number-wrapper{
    display: flex;
    align-items: center;
    gap: 10px
}
