#address-map{
  height: 50vh;
  position: relative;
}

#address-map.blur > div:first-child{
  filter: blur(4px);
}

#address-map #map-overlay{
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

#map-overlay .map-overlay-inner{
  background: var(--main-bg);
  min-width: 280px;
  padding: 40px;
  max-width: 380px;
  border-radius: 5px;
}

#map-overlay .map-overlay-inner .title h1{
  margin: 0 0 20px;
  font-size: var(--font-xl);
  text-align: center;
}

#mode-selector .mode-list{
  display: flex;
  flex-direction: column;
  gap: 10px
}

#address-help-box{
  display: flex;
  align-items: center;
  background: var(--ui-bg);
  padding: 100px 40px;
  border-bottom: 1px solid var(--ui-border);
}

#address-help-box .icon{
  margin-right: 40px;
}

#address-help-box .title{
  font-size: var(--font-xxl);
  margin: 10px 0;
}

#address-help-box .desc{
  font-size: var(--font-m);
  color: var(--ui-placeholder)
}

@media only screen and (max-width: 414px) {

  #address-help-box{
    padding: 40px;
    flex-direction: column;
    text-align: center;
  }

  #address-help-box .icon{
    margin-right: 20px;
  }

  #map-overlay .map-overlay-inner{
    max-width: none;
  }

  #address-help-box .title{
    font-size: var(--font-xl)
  }

}
