#support .cover-image{
  height: 360px
}

#support .call-to-action-form-wrapper{
  justify-content: center;
}

#support .call-to-action-form-wrapper > div{
  display: flex;
  flex-direction: column;
}

#support #search{
  background: var(--main-bg);
  padding: 10px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  border-radius: 5px;
}

#support #search input{
  flex: 1;
  margin-left: 10px;
}

#support #search .fa{
  color: var(--main-color);
  font-size: var(--font-xxl);
}

#support .help-box{
  justify-content: center;
}

#faqs{
  display: flex;
}

#faqs .faqs-menu{
  width: 280px;
  border-right: 1px solid var(--ui-border);
  margin-right: 20px;
  padding-right: 20px;
}

#faqs .faqs-menu .topic-title{
  font-size: var(--font-l)
}

#faqs .faqs-menu ul{
  margin-bottom: 40px;
}

#faqs .faqs-menu li{
  padding: 20px;
  border-bottom: 1px solid var(--ui-border);
  cursor: pointer;
}

#faqs .faqs-menu li .active{
  color: var(--main-color)
}

#faqs .faqs{
  flex: 1;
  margin:  0 0 40px;
}

#faqs .dropdown{
  width: 100%;
  margin-bottom: 20px;
}
