.icon-info{
  padding: 5px;
  margin: 0 3px 5px;
  display: flex;
  font-size: var(--font-xxs);
  align-items: center;
  background: var(--ui-bg);
  border-radius: 5px
}

.icon-info.main{
  color: var(--main-bg);
  border: 0;
  background: var(--main-color);
}

.icon-info:first-child{
  margin-left: 0
}

.icon-info:last-child{
  margin-right: 0
}

.icon-info .fa{
  align-items: center;
  display: flex;
  color: var(--ui-placeholder);
  margin-right: 5px
}

.icon-info.main .fa{
  color: var(--main-bg);
}

.icon-info .amount{
  display: flex;
}
