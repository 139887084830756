#category-menu{
  width: 100%;
}

#category-menu .navigation{
  width: 100%;
  white-space: nowrap;
  position: relative;
}

#category-menu .navigation-list{
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;

}

#category-menu .navigation-item{
  box-sizing: border-box;
  flex: 1;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
}

#category-menu .navigation-item > span{
  padding: 20px 40px;
  font-size: var(--font-s);
  box-sizing: border-box;
  display: inline-block;
  text-decoration: none;
}

#category-menu .navigation-item:hover{
  background-color: var(--main-bg-helper)
}

#category-menu .more-navigation{
  display: none;
  position: absolute;
  top: 56px;
  min-width: calc(100% + 2px);
  right: -1px;
  padding: 0;
  margin: 0;
  max-height: calc(100vh - 150px);
  list-style: none;
  min-height: 20px;
  background: var(--main-bg);
  box-shadow: 3px 5px 11px -1px rgba(0,0,0,0.1);
  border-radius: 5px;
}

#category-menu .more-navigation > .navigation-item{
  display: block;
  border-bottom: 1px solid var(--ui-border);
  border-radius: 5px;
}

#category-menu .more-navigation.opened{
  display: block;
  overflow-y: scroll;
}

#category-menu .more-item{
  position: relative;
  display: flex;
}

#category-menu .more-item .navigation-link{
  flex: 1
}
