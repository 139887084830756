.phone-number-input{
  display: flex;
}

.phone-number-input .input{
  background: transparent;
  flex: 1;
  border: navajowhite;
  font-size: var(--font-s);  
  padding: 15px;
  box-sizing: border-box;
}

.phone-number-input .change-preference button{
  height: 100%;
  padding: 0 20px;
  margin-right: 10px;
}

.phone-number-input .user-preference button i,
.phone-number-input .change-preference button i{
  margin-right: 5px;
  font-size: 14px;
}

.phone-number-input .user-preference{
  display: flex;
  flex: 1
}

.phone-number-input .user-preference > *{
  flex: 1;
  margin: 0 5px;
}

.phone-number-input .user-preference > *:first-child{
  margin-left: 0;
}

.phone-number-input .user-preference > *:last-child{
  margin-right: 0;
}

.phone-picker{
  border: 0;
  background: var(--ui-bg);
  display: flex;
  position: relative;
  border-radius: 5px;
  box-sizing: border-box;
  flex: 1
}

.phone-picker.disabled *{
  cursor: not-allowed !important;
}

.phone-picker .country{
  border-right: 1px solid var(--ui-border);
  cursor: pointer;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.phone-picker .country .country-code{
  margin: 0 5px;
}

.phone-picker .dropdown{
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  background: var(--ui-bg);
  z-index: 999;
  left: 0;
  right: 0;
  bottom: -5px;
  overflow-x: scroll;
  transform: translateY(100%);
  max-height: 0;
  transition: all 200ms ease-in;
  border: 0;
  border-radius: 5px;
  box-shadow: 0px 8px 8px 0px rgba(0,0,0,.4);
}

.phone-picker .dropdown .search-country{
  padding: 10px 25px;
  display: flex;
  align-items: center;
  background: var(--ui-bg-hvr);
}

.phone-picker .dropdown .search-country input{
  background: transparent;
}

.phone-picker .country-list {

  overflow-x: scroll;
  border-top: 0;
  z-index: 9999;
}

.phone-picker .country-list input{
  width: 100%;
  background: var(--ui-bg);
  border: 0
}

.phone-picker .dropdown.opened{
  max-height: 300px;
}

.phone-picker .country-list li{
  padding: 15px;
  cursor: pointer;
  border-bottom: 1px solid var(--ui-border)
}

.phone-picker .country-list li:last-child{
  border-bottom: 0;
}

.phone-picker .country-list li.divider{
  padding: 0;
  border-bottom: 1px solid var(--ui-border)
}

.phone-picker .country-list li span{
  margin: 0 5px;
}

.phone-picker .labeled-input{
  flex: 1;
  margin: 0 !important;
}

.phone-picker .labeled-input input{
  background-color: transparent;
  width: 100%;
  border: 0;
}

#select-country-code-modal .search-wrapper{
  flex: 1;
  background-color: var(--ui-element);
  padding: 0 15px;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 10px
}

#select-country-code-modal .search-wrapper .search{
  flex: 1;
  border: 0;
  padding: 15px 0;
  background-color: transparent;
}

@media only screen and (max-width: 600px) {

  .phone-number-input .user-preference,
  .phone-number-input{
    flex-direction: column;
  }

  .phone-number-input .user-preference > *{
    margin: 5px 0;
  }

  .phone-number-input .change-preference button{
    height: 50px;
    width: 100%;
    margin-bottom: 10px;
  }

  .phone-picker,
  .phone-picker input{
    font-size: 12px;
  }

}
