.social-login-button {
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  margin: 0;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: var(--font-s);
  color: #FFF;
}
.social-login-button:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}
.social-login-button:focus {
  outline: none;
}
.social-login-button:active {
  box-shadow: inset 0 0 0 32px rgba(0,0,0,0.1);
}

/* Facebook */
.social-login-button.facebook {
  background-color: #4C69BA;
  background-image: linear-gradient(#4C69BA, #3B55A0);
  text-shadow: 0 -1px 0 #354C8C;
}
.social-login-button.facebook:before {
  border-right: #364e92 1px solid;
  background: url('./assets/icon_facebook.png') 6px 6px no-repeat;
}
.social-login-button.facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5B7BD5;
  background-image: linear-gradient(#5B7BD5, #4864B1);
}