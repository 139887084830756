.title.styled h3{
    font-size: var(--font-m);
    font-weight: bold;
    margin: 2rem 0 1rem;
    line-height: 1.4;    
}

@media only screen and (max-width: 600px) {
    .title.styled h3{
        text-align: center;
    }
}