.more-info{
  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  flex-wrap: wrap;
  font-size: var(--font-l);
  line-height: 1.2;
}

.more-info #whatsapp-help,
.more-info a{
  margin: 0 10px;
}

.more-info #whatsapp-help{
  margin: 10px;
}
