.checkout-customer{
  display: flex;
  flex-direction: column;
  gap: 10px
}

.checkout-customer .map-actions{
  position: absolute;
  z-index: 700;
  padding: 15px;
}

.checkout-customer .selected-map{
  height: 250px;
  border-radius: 5px;
  overflow: hidden;
}


.checkout-customer .warning{
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 0 0;
  font-size: var(--font-xs);
  color: var(--ui-placeholder);
}