#cart-loader{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  background: rgba(255,255,255, .7);
  top: 0;
  bottom: 0;
  z-index: 1000;
}

#cart-loader .loader{
  z-index: 1000;
}

#checkout-summary{
  width: 400px;
  margin-left: 20px;
  position: relative;
  transition: all 0.4s linear;
}

#checkout-summary #cart{
  position: sticky;
  top: 80px;
  width: 100%;
  margin-bottom: 40px;
}

#checkout-summary #cart #cart-inner{
  width: 100%;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;
  transition: all 0.4s linear;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #ffffff;
  border: 1px solid #e6ebf1;
  border-radius: 5px;
  padding: 15px;
  box-sizing: border-box;  
}

#checkout-summary #cart-items{
  max-height: 40vh;
  overflow-y: scroll;
}

.selected-partner{
  display: flex;
  align-items: center;
}

.selected-partner > div:first-child{
  width: 80px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.selected-partner img{
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.selected-partner .info{
  display: flex;
  flex: 1;
  align-items: flex-start;
  flex-direction: column;
}

.selected-partner .title{
  line-height: 1.5;
}

.selected-partner .desc{
  margin-bottom: 5px;
  color: var(--ui-placeholder)
}

@media only screen and (max-width: 1280px) {

  #checkout-summary{
    width: 100%;
    margin-left: 0;
    padding: 0;
  }

  #checkout-summary #cart{
    top: auto;
    position: relative;
  }

  #checkout-summary #cart #cart-inner{
    position: relative;
    height: auto;
    overflow: hidden;
    box-shadow: none;
    padding: 0;
    border: 0;
  }

  #checkout-summary #cart-items{
    max-height: none;
    height: auto;
    overflow: hidden;
  }

}
