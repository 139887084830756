.slide-in{
  position: fixed;
  height: 200%;
  display: block;
  top: 60px;
  width: 0;
  z-index: 100;
  overflow: hidden;
  transition:all 200ms ease-in;
  border-color: var(--ui-border);
  opacity: 0;
  z-index: 900;
}

.slide-in.left{
  left: 0;
  opacity: 1;
  margin-left: -320px;
  width: 320px;
}
.slide-in.right{
  right: 0;
  opacity: 1;
  margin-right: -320px;
  width: 320px;
}

.slide-in.opened{
  margin-left: 0;
  margin-right: 0;
}

.slide-in.opened.left{
  border-right: 1px solid var(--ui-border)
}

.slide-in.opened.right{
  border-left: 1px solid var(--ui-border)
}

.slide-in-overflow{
  overflow-x: hidden;
}

.slide-in-translate{
  transition:all 200ms ease-in;
}

.slide-in-left-translate{
  transform:translate3d(320px, 0, 0);
  pointer-events: none;
}

.slide-in-right-translate{
  pointer-events: none;
  -webkit-transform:translate3d(-320px, 0, 0);
  -moz-transform:translate3d(-320px, 0, 0);
  -o-transform:translate3d(-320px, 0, 0);
  transform:translate3d(-320px, 0, 0);
}

.slide-in-button{
  cursor: pointer;
}

.slide-in-button *{
  pointer-events: none;
}
