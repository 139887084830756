.help-box{
  display: flex;
  align-items: center;
  padding: 0 10px;
  flex-wrap: wrap;
}

.help-box .label{
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.help-box .label i{
  margin-right: 5px;
  color: var(--main-color);
  font-size: var(--font-xxl)
}

.help-box .buttons{
  justify-content: center;
  align-items: center;
  display: flex;
}

.help-box .buttons > span{
  margin: 0 10px;
}

.help-box > *{
  margin-right: 10px;
}

.help-box > *:last-child{
  margin-right: 0;
}

@media only screen and (max-width: 600px) {

  .help-box{
    flex-direction: column;
  }

  .help-box .buttons{
    margin-top: 10px;  
  }

}
