#address-form{
  display: flex;
  flex-direction: column;
  gap: 15px
}

#address-form .help-box{
  margin-top: 20px;
  padding-right: 0;
  justify-content: center;
}

#property-types{
  display: flex;
  gap: 15px;;
  flex-wrap: wrap;
}

#property-types .property-type{
  align-items: center;
  flex: 1;
  padding: 10px;
  cursor: pointer;
  background: var(--ui-element);
  border-radius: 5px;
  border: 1px solid var(--ui-border);
  display: inline-flex;

}

#property-types .property-type .fa{
  font-size: 50px;
  text-align: center;
  width: 80px;
  color: var(--ui-color);
}

.location-fields-wrapper{
  display: flex;
  flex-direction: column;
  gap: 15px
}

.location-fields{
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 15px
}

#address-form.error .location-fields{
  justify-content: flex-start;
}

.location-fields:last-child{
  padding-left: 0;
}

.location-fields .help{
  color: var(--ui-placeholder);
  display: block;
}

.location-fields .actions{
  margin-top: auto;
  display: flex;
  gap: 15px
}

.location-fields .actions .button{
  flex: 1
}
