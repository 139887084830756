.icon-filter{
    padding-top: 20px;    
}

.icon-filter .responsive .icons{
    display: flex;
    gap: 10px;
    overflow: auto;
}

.icon-filter .swiper-slide{
    width: fit-content;
    overflow: hidden;
    border-radius: 5px;
}

.icon-filter .swiper-slide:first-child{
    padding-left: 40px;
}

.icon-filter .swiper-slide:last-child{
    padding-right: 40px;
}

.icon-filter .icons::-webkit-scrollbar {
    display: none;
}

.icons .icon{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.icons .icon label{
    margin-top: 5px;
    font-weight: bold;
    font-size: var(--font-s);
    white-space: nowrap
}

.icons .icon.selected label{
    color: var(--main-color);
}

.icons .icon img{
    width: 60px;
    height: 60px;
}

@media only screen and (max-width: 1024px) {

    .icons .icon:first-child{
        padding-left: 40px;
    }
    
    .icons .icon:last-child{
        padding-right: 40px;
    }    

}

@media only screen and (max-width: 414px) {
   
    .icon-filter .icon:first-child{
        padding-left: 20px;
    }
    
    .icon-filter .icon:last-child{
        padding-right: 20px;
    }

}