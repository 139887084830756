.select{
  position: relative;
  width: 100%;
  display: flex;
  background: var(--ui-element);
  border-radius: 5px;
  box-shadow: 0px 2px 2px 0px var(--ui-shadow);
  cursor: pointer;
}

.select .placeholder{
  color: var(--main-color);
  font-size: 12px;
  position: absolute;
  top: 10px;
  left: 15px;
  transition: all 0.2s ease-in-out;
}

.select ul{
  overflow: hidden;
  border-radius: 5px;
  position: absolute;
  display: block;
  width: 100%;
  box-sizing: border-box;
  left: 0;
  background: var(--ui-element);
  box-shadow: 0px 8px 8px 0px var(--ui-shadow);
  bottom: -5px;
  transform: translateY(100%);
  border-top: 0;
  z-index: 100;
}

.select .selected{
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px;
  width: 100%;
  background: var(--ui-element);
  color: var(--ui-text);
  padding-bottom: 12px;
}

.select.placeholder .selected{
  padding-top: 28px;
}


.select ul{
  max-height: 180px;
  overflow-y: scroll;
  box-shadow: 0px 8px 8px 0px rgba(0,0,0,.4);
  z-index: 100;
}

.select ul li{
  padding: 15px;
  border: 0;
  cursor: pointer;
  color: var(--ui-text);
  border-bottom: 1px solid var(--ui-border);
  display: block;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
}

.select ul li:hover{
  background: var(--ui-bg-helper)
}

.select ul li:last-child{
  border-bottom: 0;
}

.selected .info,
.select ul .info{
  color: var(--ui-placeholder);
  margin-top: 5px;
  font-size: var(--font-xs)
}

.select > .fa{
  position: absolute;
  right: 0;
  pointer-events: none;
  margin-right: 10px;
  font-size: 12px;
  top: 50%;
  color: var(--main-color);
  transform: translateY(-50%);
  cursor: pointer;
}

.select.placeholder .fa,
.select ul li .fa{
  margin-right: 20px;
}
