#order-invoices{
  padding: 20px 0;
}

.sliding-table{
  width: 100%;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.table{
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
  border-radius: 5px;
  overflow: hidden;
}

.table.full{
  width: 100%;
}

.table td,
.table th{
  padding: 10px 20px;
  background: #fafafa;
  border-bottom: 1px solid var(--ui-border);
  text-align: left;
}

.table tr:last-child td{
  border-bottom: 0;
}

.table th{
  background: var(--ui-bg)
}

/* For Webkit-based browsers like Chrome and Safari */
.sliding-table::-webkit-scrollbar {
  width: 5px;
}

.sliding-table::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.sliding-table::-webkit-scrollbar-track {
  background-color: transparent;
}