#product-modal .product-tags{
  justify-content: flex-start;
  margin-bottom: 5px;
  margin-top: 0;
}

#product-modal .product-tags .product-tag:first-child{
  margin-left: 0;
}

#product-modal .product-modal-info.with-thumb .product-modal-header{
  display: flex;
  gap: 15px;
}

#product-modal .product-modal-info.with-thumb .product-modal-header img{
  width: 150px;
}

#product-modal .product-modal-info .inner{
  padding: 20px 20px 0;
}

#product-modal .product-modal-info .thumb{
  display: flex;
  align-items: center;
  justify-content: center; 
}

#product-modal .product-modal-info{
  width: 90vw;
  max-width: 720px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

#product-modal .product-modal-content{
  overflow-y: auto;

}

#product-modal .product-modal-footer{
  margin-top: auto;
  display: flex;
  min-height: 40px;
  padding: 20px;
  border-top: 1px solid var(--ui-border);
}

#product-modal .product-modal-footer .quantity-selector{
  margin: 0;
  text-align: center;
}

#product-modal .product-modal-footer #add-to-cart-button{
  margin-left: auto;
  font-size: var(--font-s);
  padding: 0 12px;
}

#product-modal .product-modal-info .title h2{
  font-size: var(--font-l);
  margin-bottom: 10px;
}

#product-modal .product-modal-content .desc{
  color: var(--ui-placeholder);
  line-height: 1.2;
  font-size: var(--font-s);
  margin-bottom: 10px;
}

#product-modal .product-modal-content .vendor-name{
  color: var(--ui-placeholder);
  font-size: var(--font-s);
  line-height: 1.2;
  font-weight: 400;
  margin: 0 0 5px;
}

#product-modal .product-modal-content .vendor-name .fa{
  margin-right: 5px;
}

#product-modal .product-modal-thumb{
  width: 40vw;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  min-height: 60vh;
}

#product-modal .product-modal-cover{
  max-height: 300px;
  border-radius: 5px;
  height: 30vh;
  margin: 0;
  background-size: cover;
  background-position: center;
  position: relative;
}

#product-modal .product-modal-cover .product-tags{
  right: 15px;
  bottom: 15px;
  position: absolute;
}

#product-modal .product-modal-cover .product-tags .tag{
  background: var(--main-bg);
  padding: 5px;
  margin: 0 5px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

#product-modal .labeled-textarea{
  margin-top: 20px;
}

@media only screen and (max-width: 600px) {

  #product-modal .product-modal-info.with-thumb .product-modal-header img{
    width: 150px;
  }

  #product-modal{
    flex-direction: column;
  }

  #product-modal .product-modal-info,
  #product-modal .product-modal-thumb{
    width: 90vw;
    min-height: 0
  }

}

@media only screen and (max-width: 414px) {

  #product-modal .labeled-textarea ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    line-height: 1.2;
    font-size: var(--font-xxs)
  }

  #product-modal .labeled-textarea ::-moz-placeholder { /* Firefox 19+ */
    line-height: 1.2;
    font-size: var(--font-xxs)
  }

  #product-modal .labeled-textarea :-ms-input-placeholder { /* IE 10+ */
    line-height: 1.2;
    font-size: var(--font-xxs)
  }

  #product-modal .labeled-textarea :-moz-placeholder { /* Firefox 18- */
    line-height: 1.2;
    font-size: var(--font-xxs)
  }

  #product-modal .labeled-textarea.populated label textarea,
  #product-modal .labeled-textarea label textarea:focus{
    padding-top: 45px;
  }

}

@media only screen and (max-width: 360px) {

  #product-modal .labeled-textarea.populated label textarea,
  #product-modal .labeled-textarea label textarea:focus{
    padding-top: 55px;
  }

  #product-modal .product-modal-footer{
    min-height: 30px;
  }

}
