#cart-wrapper{
  transition: all 0.4s linear;
  position: relative;
  height: 100%;
}

#cart .selected-partner{
  margin-bottom: 10px;
}

#cart-wrapper #cart{
  position: sticky;
  top: 160px;
  height: 100%;
}

#cart-wrapper #cart #cart-inner{
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid transparent;
  padding: 15px;
  box-shadow: 0px 0px 5px 0px rgba(200,200,200,0.75);
  transition: all 0.4s linear;
  background: var(--main-bg);
}

#cart-wrapper #cart-items{
  max-height: calc(100vh - 310px);
  overflow-y: scroll;
}

#go-to-checkout{
  display: block;
  width: 100%;
  background: var(--main-color);
  color: var(--main-bg);
  padding: 10px;
  font-size: var(--font-m);
}

#cart-items{
  display: block;
}

#cart-items .cart-item{
  padding: 15px;
  margin: 0;
  display: flex;
  border-bottom: 1px solid var(--ui-border)
}

#cart-items .cart-item .not-available{
  margin-top: 5px;
  font-size: var(--font-xs);
  display: block;
  color: var(--invalid-color)
}

#cart-items .cart-item:last-child{
 border: 0;
}

#cart-items .cart-item .qty{
  margin-right: auto;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--font-m);
  line-height: 1
}

#cart-items .cart-item .qty .fa{
  font-size: var(--font-xs);
  cursor: pointer;
  color: var(--ui-placeholder)
}

#cart-items .cart-item .qty .fa:hover{
  color: var(--ui-color)
}

#cart-items .cart-item .qty > div{
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

#cart-items .cart-item .instructions{
  font-size: var(--font-xs);
  padding-left: 5px;
  color: var(--ui-placeholder)
}

 #cart-items .cart-item .item-info{
  margin: 0 auto;
  flex: 1
}

#cart-items .cart-item .item-info .title{
  font-size: var(--font-s)
}

 #cart-items .cart-item > .price{
  margin-left: auto;
  display: flex;
  flex-direction: column;
  text-align: right;
}

#cart-items .cart-item .price .free-delivery-badge{
  margin-top: 5px;
}

#cart-items .cart-item .price .fa{
  margin-top: auto;
  margin-left: auto;
  color: var(--ui-placeholder);
  cursor: pointer;
}

#cart-items .cart-item .price .fa:hover{
  color: var(--invalid-color)
}

#cart-items .cart-item .selected-options{
  padding-left: 5px;
}

#cart-items .cart-item .selected-option{
  font-size: var(--font-xs);
  line-height: 1.2;
  color: var(--ui-placeholder)
}

#cart-items .cart-item .selected-option .price{
  margin-left: 10px;
  color: var(--ui-text)
}

#cart-totals{
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

#cart-totals .cart-total{
  margin: 15px 0;
  display: flex;
  gap: 5px
}

#cart-totals .cart-total .qty{
  font-size: var(--font-xs);
  color: var(--ui-placeholder);
}

#cart-totals .cart-total .price{
  color: var(--ui-color);
  margin-left: auto;
}

#cart .empty-cart{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;  
  padding: 20px 0;
}

#cart .empty-cart .fa{
  font-size: 40px
}

#cart .empty-cart .button{
  width: 100%;
  box-sizing: border-box;  
}