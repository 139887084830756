#pending-purchases .order-alerts{
  display: flex;
  justify-content: center;
  align-items: center;
}

#pending-purchases .order-alerts .large-inflow{
  font-size: var(--font-s);
  line-height: var(--font-l);
  display: flex;
  align-items: center;
  text-align: center;
  margin: 15px 0;
}

#pending-purchases .order-alerts .large-inflow .fa{
  color: var(--warning-color);
  margin-right: 10px;
  font-size: var(--font-l);
}

#pending-purchases .no-orders{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

#pending-purchases .no-orders .help{
  font-size: var(--font-l);
  color: var(--ui-placeholder);
  text-align: center;
  margin-bottom: 20px;
}
