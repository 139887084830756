.faq-item{
  margin: 0 0 40px;
}

.faq-item .question{
  padding-bottom: 10px;
  font-weight: bold;
  font-size: var(--font-m);
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--ui-border);
  margin-bottom: 10px;
}

.faq-item .question .fas{
  font-size: var(--font-xxl);
  color: var(--main-color);
  margin-right: 20px;
}

.faq-item .answer{
  line-height: 1.2;
}

.faq-item .answer a{
  color: var(--main-color);
}

.faq-item .answer p{
  margin: 10px 0;
}

.faq-item .answer ul{
  margin-left: 20px;
  padding-left: 20px;
  list-style: disc;
}

.faq-item .answer ul li{
  margin: 5px 0;
}
