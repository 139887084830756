#order-modal .partner-name,
#order-modal .partner-box{
  display: flex;
  flex: 1;
  align-items: center;
}

#order-modal .partner-name{
  font-size: var(--font-l);
}

#order-modal .partner-name .button{
  margin-left: auto;
}

#order-modal .tabs-menu {
  overflow-x: auto;
  overflow-y: hidden;
}

#order-modal .tabs-menu::-webkit-scrollbar{
  display: none;
}

#order-modal .partner-logo{
  width: 75px;
  height: 75px;
  display: flex;
  align-items: center;
  margin-right: 20px;
}

#order-modal .partner-logo img{
  width: 100%;
  border-radius: 5px;
  height: auto;
}

.tabs-menu{
  margin-top: 20px;
  display: flex;

}

.tabs-menu-item{
  margin: 0 0 -1px;
  padding: 15px 25px;
  flex: 1;
  font-weight: 600;
  border-radius: 5px;
  text-align: center;
  color: var(--ui-placeholder);
  cursor: pointer;
  border: 1px solid transparent;
  border-bottom-color: var(--ui-border)
}

.tabs-menu-item.active{
  color: var(--main-color);
  border: 1px solid var(--ui-border);
  border-top: 2px solid var(--main-color);
  border-bottom: 1px solid #fff;
}

.tabs-wrapper .tabs .tab{
  display: none;
}

.tabs-wrapper .tabs .tab.active{
  display: block;
}

@media only screen and (max-width: 600px) {

  #order-modal{
    flex-direction: column;
  }

  #order-modal .order-modal-info,
  #order-modal .order-modal-image{
    width: 90vw
  }

}
