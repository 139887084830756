.loader,
.loader:after{
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.loader.small,
.loader.small:after{
  width: 10px;
  height: 10px;
  border-width: 3px !important
}

.loader{
  margin: 0 auto;
  font-size: 10px;
  position: relative;
  border-top: 5px solid rgba(255, 255, 255, 0.2);
  border-right: 5px solid rgba(255, 255, 255, 0.2);
  border-bottom: 5px solid rgba(255, 255, 255, 0.2);
  border-left: 5px solid #ffffff;
  transform: translateZ(0);
  animation: loading 1.1s infinite linear;
}

.loader.main{
  border-left: 5px solid var(--main-color);
  border-top: 5px solid rgba(255, 42, 34, 0.2);
  border-right: 5px solid rgba(255, 42, 34, 0.2);
  border-bottom: 5px solid rgba(255, 42, 34, 0.2);
}

.loader-overlay{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255,255,255,.6);
  z-index: 1000;
}

.loader-overlay.dark{
  background-color: rgba(50, 50, 50, 0.5)
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
