.actions{
  display: flex;
  gap: 5px
}

.actions.full{
  flex: 1
}

.actions .button{
  flex: 1
}

.actions.inline .button{
  flex: none
}

@media only screen and (max-width: 414px) {

  .actions{
    flex-wrap: wrap;
    flex-direction: column;
  }

  .actions .button{
    flex-basis: 100%;
  }

}
