.actions-dropdown{
  position: relative;
}

.actions-dropdown > .button{
  font-size: var(--font-s);
  width: 100%;
}

.actions-dropdown > .button .fa{
  margin-right: 10px;
}

.actions-dropdown .actions-dropdown-dropdown{
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  left: 0;
  background: var(--ui-bg);
  border: 1px solid var(--ui-border);
  border-radius: 5px;
  display: none;
  z-index: 10;
}

.actions-dropdown .actions-dropdown-dropdown.opened{
  display: block;
}

.actions-dropdown .actions-dropdown-dropdown li{
  white-space: nowrap;
  padding: 15px;
  font-size: var(--font-s);
  cursor: pointer;
  border-bottom: 1px solid var(--ui-border);
}

.actions-dropdown .actions-dropdown-dropdown li:first-child{
  border-top-left-radius: 5px;
  border-top-right-radius: 5px
}

.actions-dropdown .actions-dropdown-dropdown li:last-child{
  border-bottom: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.actions-dropdown .actions-dropdown-dropdown li .fab,
.actions-dropdown .actions-dropdown-dropdown li .fas,
.actions-dropdown .actions-dropdown-dropdown li .fa{
  margin-right: 5px;
}

.actions-dropdown .actions-dropdown-dropdown li:hover{
  background: var(--ui-bg-hvr)
}

/* SMALL */

.actions-dropdown.small > .button{
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: var(--font-xs)
}

.actions-dropdown.small .actions-dropdown-dropdown{
  transform: translate(-35px, 0%);
}

.actions-dropdown.small .actions-dropdown-dropdown li{
  padding: 10px;
  font-size: var(--font-xs);
}
