.skeleton-loader .skeleton .skeleton--content {
  padding: 15px;
  position: relative;
}
.skeleton-loader .skeleton .skeleton--content .skeleton--row {
  display: flex;
  align-items: center;
}
.skeleton-loader .skeleton .skeleton--content .skeleton--col {
  display: flex;
  flex-direction: column;
}
.skeleton-loader .skeleton .skeleton--content .skeleton-loader {
  background: #f6f7f8;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderSkeleton;
  -webkit-animation-timing-function: linear;
  background-image: -webkit-gradient(linear,left center,right center,from(#f6f7f8),color-stop(.2,#edeef1),color-stop(.4,#f6f7f8),to(#f6f7f8));
  background-image: -webkit-linear-gradient(left,#f6f7f8 0%,#edeef1 20%,#f6f7f8 40%,#f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  height: 104px;
  position: relative;
}
.skeleton-loader .skeleton .skeleton--content .skeleton--title {
  margin: 5px 10px;
  height: 20px;
  width: 200px;
}
.skeleton-loader .skeleton .skeleton--content .skeleton--hr {
  height: 2px;
  width: calc(100% - 20px);
  margin: 0 10px 10px;
}
.skeleton-loader .skeleton .skeleton--content .skeleton--line {
  height: 10px;
  width: calc(100% - 20px);
  margin: 10px;
}
.skeleton-loader .skeleton .skeleton--content .skeleton--line.skeleton--line__short {
  width: 120px;
}
.skeleton-loader .skeleton .skeleton--content .skeleton--circle {
  margin: 5px 10px 10px;
  height: 60px;
  width: 60px;
  border-radius: 10px;
}
.skeleton-loader .skeleton .skeleton--content .fl {
  display: inline-block;
  width: auto;
  vertical-align: text-top;
}
@-webkit-keyframes placeholderSkeleton {
  0% {
    background-position: -468px 0;
 }
  100% {
    background-position: 468px 0;
 }
}
