#place-purchase{
  border-top: 1px solid var(--ui-border);
  position: relative;
}

#place-purchase #payment-loading-overlay{
  position: absolute;
  background: rgba(255,255,255,.9);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  flex-direction: column;
}

#place-purchase #payment-loading-overlay .warning{
  padding: 0 40px;
  text-align: center;
  margin-top: 10px;
  line-height: 1.4
}

#place-purchase{
  display: flex;
  flex-direction: column;
  gap: 10px
}

#place-purchase-loader{
  position: relative;
  min-height: 340px;
}