#request-invoice{
  display: flex;
  flex-wrap: wrap;
  gap: 20px
}

#request-invoice > *{
  flex: 1;
  min-width: 600px;
}

#request-invoice > *:first-child{
  margin-left: 0;
}

#request-invoice > *:last-child{
  margin-right: 0;
}

#request-invoice .help{
  margin-bottom: 20px;
  text-align: center;
}

#purchases-list{
  background: var(--ui-bg);
}

#purchases-list .purchase{
  display: flex;
  align-items: center;
  margin: 0 0 10px;
  border-bottom: 1px solid var(--ui-border);
  padding: 10px;
  cursor: pointer;
}

#purchases-list .purchase:last-child{
  margin-bottom: 0;
}

#purchases-list .purchase .title{
  margin-bottom: 10px;
}

#purchases-list .purchase .vendor-logo{
  width: 60px;
  height: 60px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#purchases-list .purchase .vendor-logo img{
  width: 100%;
  height: auto;
}

#purchases-list .purchase .details{
  flex: 1;
  display: flex;
}

#purchases-list .purchase .details .price-wrapper{
  margin-left: auto;
  display: flex;
}

#purchases-list .purchase .details .price{
  margin-left: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
}

#purchases-list .purchase .details .invoiceable-price{
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-left: 20px;
}

#purchases-list .purchase .details .price-wrapper .warn{
  font-size: var(--font-xxs);
  margin-bottom: 5px;
}

#purchases-list .purchase .details .price-wrapper .amount{
  color: var(--main-color);
  margin-top: auto;
}

#purchases-list .purchase .details .date{
  color: var(--ui-placeholder);
  font-size: var(--font-xs);
  margin-top: auto;
}

#purchases-list .purchase .checkbox{
  margin-left: 20px;
}

#purchases-list .actions{
  display: flex;
  justify-content: center;
  padding: 10px 10px 20px;
}

#purchases-list .actions .button{
  width: 100%;
}

#request-invoice #request-invoice-form .form{
  display: flex;
  align-items: center;
  flex-direction: column;
}

#request-invoice #request-invoice-form .form > *{
  width: 100%;
  margin-bottom: 10px;
}
