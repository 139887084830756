.product-tags{
  display: flex;
  margin-top: auto;
  white-space: nowrap;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
}

.product-tag{
  margin: 5px 0 0;
  color: var(--main-color);
  cursor: pointer;
  font-size: var(--font-s);
  display: flex;
}

.product-tag.green{
  color: var(--valid-color)
}

.product-tag .vegetarian{
  color: var(--valid-color)
}

.product-tag .vegan{
  color: var(--valid-color);
  border: 1px solid var(--valid-color) ;
  border-radius: 50%;
  width: var(--font-s);
  height: var(--font-s);
  box-sizing: border-box;
  display: block;
  text-align: center;
}
