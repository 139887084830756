#orders{
  background: var(--main-bg);
}

#orders .order{
  background: var(--main-bg);
  border-radius: 5px;
  margin: 20px 0;
  padding: 40px; position: relative;
  box-shadow: 0px 0px 5px 0px rgba(200,200,200,0.75);
}

#orders .order .vendor-logo{
  align-items: center;
  display: flex;
  width: 80px;
  height: 80px;
  flex: none;
  border-radius: 5px;
  overflow: hidden;
}

#orders .order .vendor-logo picture{
  width: 80px;
  height: 80px;
}

#orders .order .vendor-logo img{
    height: auto;
    width: 80px;
}

#orders .order .order-vendor-box{
  display: flex;
  align-items: center;
  flex:1;
}

#orders .order .vendor-name{
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

#orders .order .vendor-name .title{
  border-bottom: 1px solid var(--ui-border);
  padding-bottom: 5px;
  margin-bottom: 5px;
}

#orders .order .vendor-name span:last-child{
  color: var(--ui-placeholder);
}

.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}

@media only screen and (max-width: 414px) {

  #orders .order{
    padding: 20px
  }

  #orders .order .vendor-name{
    width: 100%;
  }

}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
