.order-address .map{
  height: 320px;
  margin-top: 10px;
}

.order-address .address-info{
  width: 100%;
}

.order-address .infos{
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px
}

.order-address .infos .item{
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 15px 10px;
  background-color: var(--ui-element);
  border-radius: 5px;
  cursor: pointer;
}

.order-address .infos .item .label{
  display: flex;
  align-items: center;
  color: var(--ui-placeholder);
  font-size: var(--font-s);
  line-height: 1;  
}

.order-address .infos .item .fa{
  color: var(--main-color);
  width: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.order-address .address-info td{
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--ui-border);
}

.order-address .address-info tr td:last-child{
  text-align: right;
}

.order-address .address-info td:first-child{
    align-items: center;
    font-size: var(--font-s);
    white-space: nowrap;
}

.order-address .address-info td .fa{
  color: var(--main-color);
  margin-right: 10px;
}
