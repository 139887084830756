.switch-box{
  display: flex;
  align-items: center;
  background: var(--ui-element);
  border-radius: 5px;
  box-shadow: 0px 2px 2px 0px var(--ui-shadow);
  padding: 15px;
  gap: 15px;
}

.switch-box .switch{
  flex: 1
}