#vendor-promotions {
  display: flex;
  gap: 10px;
  overflow: auto;
}

.promotions-title{
    font-size: var(--font-s);
    display: flex;
    background: var(--valid-color);
    border-radius: 5px;
    color: white;
    padding: 10px;
    align-items: center;
    cursor: pointer;
  }
  
  .promotions-title .title{
    font-size: var(--font-s);
  }
  
  .promotions-title i{
    font-size: var(--font-m);
  }
  
  .promotions-title .info{
    margin-left: 5px;
  }
  
  .promotions-title .info span{
    font-size: var(--font-s)
  }

  .promotion-modal .promotion-info{
    line-height: 1.2;
  }
  