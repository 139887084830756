.order-status-text h2{
  font-size: var(--font-l);
  margin-bottom: 10px;
}

.order-status-text h3{
  color: var(--ui-placeholder);
  line-height: 1.2;
}

.order-status-text > *{
  margin: 5px 0;
}
