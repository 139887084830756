.tooltip-wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-wrapper.no-wrap .tooltip{
  white-space: nowrap;
}

.tooltip-wrapper .tooltip {
  background: var(--ui-bg);
  border: 1px solid var(--ui-border);
  bottom: 100%;
  border-radius: 5px;
  color: var(--ui-text);
  min-width: 150px;
  display: block;
  right: 0;
  margin-bottom: 15px;
  opacity: 0;
  padding: 10px;
  box-sizing: border-box;
  pointer-events: none;
  position: absolute;
  z-index: 6000;
  transform: translateY(10px);
  transition: all .25s ease-out;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

.tooltip-wrapper.right .tooltip{
  right: auto;
  left: 0;
}

.tooltip-wrapper.right .tooltip:after{
  left: 4px;
  right: auto;
}

/* This bridges the gap so you can mouse into the tooltip without it disappearing */
.tooltip-wrapper .tooltip:before {
  bottom: -20px;
  content: " ";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
}

/* CSS Triangles - see Trevor's post */
.tooltip-wrapper .tooltip:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid var(--ui-border) 10px;
  bottom: -11px;
  content: " ";
  height: 0;
  right: 4px;
  margin-left: 0;
  position: absolute;
  width: 0;
}

.tooltip-wrapper > *:hover + .tooltip {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0px);
}

/* IE can just show/hide with no transition */
.lte8 .tooltip-wrapper .tooltip {
  display: none;
}

.lte8 .tooltip-wrapper:hover .tooltip {
  display: block;
}

.tooltip-wrapper.green .tooltip{
  border-color: green;
  background: green;
  color: white;
}

.tooltip-wrapper.green .tooltip:after{
  border-top-color: green;
}

.tooltip-wrapper.main .tooltip{
  border-color: var(--main-color);
  background: var(--main-color);
  color: white;
}

.tooltip-wrapper.main .tooltip:after{
  border-top-color: var(--main-color);
}
