.link > *{
  color: var(--main-color);
  transition: 0.3s;
}

.link:hover > *{
  color: var(--main-color-hvr)
}

.link.icon{
  display: flex;
}

.link .icon{
  margin-right: 5px;
  color: var(--main-color);
}

.link.button:visited{
  color: inherit;
}

.link.button:hover,
.link.button{
  text-decoration: none;
}

.link.plain,
.link.plain:hover,
.link.plain > *,
.link.plain:hover > *,
.link.plain:visited{
  color: inherit;
  text-decoration: none;
}