.otp-input{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.otp-input .otp-number{
    display:inline-block;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    text-align:center;
    font-size: var(--font-l);
}