#detect-location .error-box{
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px
}

#detect-location .error-box .error-message{
  color: var(--ui-placeholder)
}

#detect-location .placeholder,
#detect-location .helps .title{
  display: block;

  text-align: center;
}

#detect-location .helps{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px
}

#detect-location .helps button .fab{
  margin-right: 5px;
  font-size: var(--font-m)
}
