.quantity-selector{
  display: flex;
  align-items: center;
}

.quantity-selector .qty,
.quantity-selector .clip{
  min-width: 20px;
  padding: 0 10px;
  height: 40px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--ui-border)
}

.quantity-selector .clip{
  background: var(--ui-bg);
  cursor: pointer;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.quantity-selector .clip:hover{
  background: var(--ui-bg-hvr);
}

.quantity-selector .qty{
  border-left: 0;
  border-right: 0;
}

.quantity-selector .clip:first-child{
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.quantity-selector .clip:last-child{
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

@media only screen and (max-width: 360px) {

  .quantity-selector .qty,
  .quantity-selector .clip{
    width: 30px;
    height: 30px;
  }

}
