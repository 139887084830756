#payment-methods{
  position: relative;
  display: flex;
  flex-direction: column;
}

#payment-methods-list .badge{
  margin-left: auto;
  display: flex;
  align-items: center;
}

#payment-methods-list .badge svg{
  height: 30px;
  width: auto;
  display: block;
}

#payment-methods-list .options{
  display: flex;
}

#payment-methods-list .payment-method-options{
  flex-direction: column;
  padding-left: 44px
}

#payment-methods-list .payment-method-options#cod .bills{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

#payment-methods .select-list .list-item{
  flex-direction: column;
}

#payment-methods-list .payment-method-options .checkbox{
  margin: 10px 0;
}

#payment-methods .not-found-wrapper{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

#payment-methods .not-found{
  gap: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

#payment-methods .not-found .fa{
  font-size: 80px;
  color: var(--ui-placeholder)
}

@media only screen and (max-width: 600px) {

  #payment-methods-list .badge{
    display: flex;
    align-items: center;
  }

  #payment-methods-list .badge img{
    height: 25px;
  }

  #payment-methods-list .payment-method-options{
    padding-left: 0
  }

}
