.checkout-option{
  border: 0;
  padding: 15px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  border: 1px solid var(--ui-border);
}

.checkout-option .info{
  display: flex;
  flex: 1;
  gap: 15px;
  align-items: center;
}

.checkout-option .icon .fa{
  font-size: var(--font-m);
  color: var(--ui-placeholder);
  min-width: 40px;
  text-align: center;
}

.checkout-option .icon .fa.fa-leaf{
  color: #42a942;
}

.checkout-option .toggle,
.checkout-option .checkbox,
.checkout-option .switch,
.checkout-option .quantity-selector{
  margin-left: auto;
}

.checkout-option .texts{
  display: flex;
  flex-direction: column;
  line-height: 1.2
}

.checkout-option .checkout-option-value{
  margin-left: auto;
  display: flex;
  flex-direction: column;
  gap: 5px
}
.checkout-option .checkout-option-value > .placeholder{
  font-size: var(--font-xs);
  color: var(--ui-placeholder)
}

.checkout-option .checkout-option-value .value{
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  text-align: right;
}

.checkout-option .checkout-option-value .fa{
  cursor: pointer;
}

.checkout-option .texts .label{
  white-space: nowrap;
}

.checkout-option .texts .desc{
  color: var(--ui-placeholder);
}

.checkout-option .checkbox input[type='checkbox'] + label > span{
  background: lightgrey;
}


@media only screen and (max-width: 700px) {

  .checkout-option .info{
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .checkout-option .toggle,
  .checkout-option .switch,
  .checkout-option .checkout-option-value,
  .checkout-option .checkbox,
  .checkout-option .quantity-selector{
    margin-left: 0;
    margin-top: 10px;
  }

}

@media only screen and (max-width: 560px) {

  .checkout-option{
    flex-direction: column;
  }

  .checkout-option .info{
    margin-top: 10px;
  }

}
